export const Search_Mock_Data = {
  products: [
    {
      name: "test 2",
      image: {
        url: "/uploads/product_XT_550_ed153ff667.jpg",
      },
      slug: "test-2",
      shortDescription: null,
    },
    {
      name: "test 3",
      image: {
        url: "/uploads/E910_59a583f9dc.png",
      },
      slug: "test-3",
      shortDescription: null,
    },
  ],
  applications: [
    {
      title: "Energy production",
      thumbnail: {
        url: "/uploads/application_energy_production_thumbnail_75fef469a7.jpg",
      },
      shortDescription:
        "Older, conventional methods of measuring turbines are very time-consuming. With a laser measurement system, work can be performed much faster and with the greatest precision. The possibility of measurement results being easily and clearly documented is also a great advantage.",
      slug: "energy-production",
    },
  ],
};

import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import React from "react";

gsap.registerPlugin(ScrollToPlugin);

type ScrollToTarget = number | string | Element | Function | ScrollToPlugin.Vars;

export const ScrollTo = (container: gsap.TweenTarget | React.RefObject<HTMLElement>, target: ScrollToTarget, duration: number = 0.25) => {
  return gsap.to((container as React.RefObject<HTMLElement>).current || container, { duration, scrollTo: target });
};

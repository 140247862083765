export const setCookie = (cname: string, cvalue: string | number, exdays: number) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  const hostname = !/^(?:(?:^|\.)(?:2(?:5[0-5]|[0-4]\d)|1?\d?\d)){4}$/.test(window.location.hostname) ? window.location.hostname.split(".").reverse()[1] : undefined;
  const domain = hostname ? `domain=.${hostname}.${window.location.hostname.split(".").reverse()[0]}` : "";
  document.cookie = cname + "=" + cvalue + ";" + expires + ";" + domain + ";path=/";
};

export const getCookie = (cname: string) => {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const isYoutubeUrl = (url: string | undefined | null) => {
  if (!url) return false;
  return /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/.test(url);
};

export const extractContent = (content?: string) => {
  if (!content) return null;

  const span = document.createElement("span");
  span.innerHTML = content;
  return span.textContent || span.innerText;
};

export const formatDateCustom = (date: string = "", locale: string = "en") => {
  const _date = new Date(date);
  return _date.toString() !== "Invalid Date" ? new Intl.DateTimeFormat(locale, { month: "long", day: "numeric", year: "numeric" }).format(_date) : "";
};

export const toNormalizeString = (str: string | null) => {
  if (!str) return;

  str = str.toLowerCase();

  // xóa dấu
  str = str
    .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
    .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp

  // Thay ký tự đĐ
  str = str.replace(/[đĐ]/g, "d");

  // Xóa ký tự đặc biệt
  str = str.replace(/([^0-9a-z-\s])/g, "");

  // Xóa ký tự - liên tiếp
  str = str.replace(/-+/g, "-");

  // xóa phần dư - ở đầu & cuối
  str = str.replace(/^-+|-+$/g, "");

  // return
  return str;
};

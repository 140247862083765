// Footer
import FacebookIcon from "assets/images/icons/facebook-icon.svg";
import LinkedinIcon from "assets/images/icons/linkedin-icon.svg";

export const Common_Mock_Data: any = {
  header: [
    {
      label: "PRODUCTS",
      url: "",
      child: [
        {
          label: "GEOMETRIC MEASUREMENT",
          url: "/products/geometric-measurement",
        },
        {
          label: "SHAFT ALIGNMENT",
          url: "/products/shaft-alignment",
        },
        {
          label: "OTHER",
          url: "/products/other",
        },
      ],
    },
    {
      label: "APPLICATION",
      url: "/application",
      child: [
        {
          label: "MARINE APPLICATION",
          url: "/application/marine-application",
        },
        {
          label: "ENERGY PRODUCTION",
          url: "/application/energy-production",
        },
        {
          label: "WIND POWER",
          url: "/application/win-power",
        },
        {
          label: "OIL & GAS",
          url: "/application/oil-n-gas",
        },
      ],
    },
    {
      label: "SERVICES",
      url: "/services",
    },
    {
      label: "ABOUT US",
      url: "/about-us",
    },
    {
      label: "CONTACT US",
      url: "/contact",
    },
    {
      label: "BLOG",
      url: "/blog",
    },
  ],
  footer: {
    leftContent: `<h2 style="margin-left:0px;"><strong>VIETNAM LASER ALIGNMENT CO., LTD</strong></h2><p style="margin-left:0px;"><strong>Hotline: <a href="tel: +84902586039">+84 28 3547 4050</a></strong><br><strong>Fax: +84 28 3547 2641</strong><br><strong>Email: <a href="mailto:infos@easylaser.vn">infos@easylaser.vn</a> & <a href="mailto:vlainfo@vnlaseralignment.com"> vlainfo@vnlaseralignment.com</a></strong></p>
    <p>&nbsp;</p>
    <p><a href="https://www.facebook.com/vla.company" target="_blank" rel="noopener noreferrer"><img src="${FacebookIcon}" style="width:5.62%;"></a> &nbsp;&nbsp;
    <a href="https://www.linkedin.com/company/26610261" target="_blank" rel="noopener noreferrer"><img src="${LinkedinIcon}" alt="twitter-color.png" style="width:5.62%;"></a></p>`,
    rightContent: `<h3 style="margin-left:0px;"><strong>Head Office</strong></h3><p style="margin-left:0px;">16 Yen The, Ward 2, Tan Binh District, HCMC<br>Hotline: <a href="tel: +84938435339">0938 435 339</a></p><h3 style="margin-left:0px;"><strong>Vung Tau Office</strong></h3><p style="margin-left:0px;">D1-2/8 Khu đô Thị Chí Linh, Phường Nguyễn An Ninh, TP. Vũng Tàu, Viet Nam<br>Hotline: <a href="tel:+84902586039">0902 586 039</a></p><h3 style="margin-left:0px;"></p>`,
  },
  commonText: { zalo: "0000000" },
};

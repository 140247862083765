import { BlogDetailCardType } from "pages/blog-detail/BlogDetail";
import { BlogCommonDataType } from "pages/blogs/Blogs";

import BannerDesktop from "assets/images/blog/Blog-bannerDesktop.jpg";
import BlogThumbnail from "assets/images/blog/Blog-thumbnail.jpg";
import BlogDetailBannerDesktop from "assets/images/blog/BlogDetail-desktop-banner.jpg";

export const Blog_Mock_Data: BlogCommonDataType = {
  blogPage: {
    allBlogsText: "ALL BLOGS",
    description: "Lorem ipsum dolor sit amet consectetur. Massa morbi neque sodales eget turpis felis iaculis proin at. Amet netus commodo et id lobortis morbi aliquam placerat.",
    desktopBg: { url: BannerDesktop },
  },
  blogs: [
    {
      title: "MẤT BAO LÂU ĐỂ CÂN CHỈNH MỘT HỆ THỐNG MÁY?",
      shortDescription: `<p>Những người hoạch định thường gặp nhiều khó khăn trong việc phân bổ thời gian cho công tác cân chỉnh đồng tâm trục.</p>`,
      published_at: "2023-02-03T15:56:22.000Z",
      type: "EVENT",
      thumbnail: { url: BlogThumbnail },
      author: "Admin",
      content: `<h3><span><strong>Những người hoạch định thường gặp nhiều khó khăn trong việc phân bổ thời gian cho công tác cân chỉnh đồng tâm trục. Trong nhiều trường hợp, có thể họ sẽ phỏng đoán dựa trên thời gian trung bình đã từng làm trước đó. Mọi chuyện có thật sự đơn giản như thế?</strong></span></h3>
  
      <p><span>Tôi đã tiến hành cân chỉnh một mô hình motor và máy bơm trong phòng thực hành. Trình bày từng bước để cân chỉnh máy. Thực hiện cân chỉnh theo từng bước an toàn, và đồng thời máy cũng đã được cân chỉnh thô trước đó. Giá trị chân mềm (soft foot) đã trong vùng dung sai. Công việc hoàn thành trong vòng 45 phút, với giá trị dung sai cho phép của tốc độ 1200 RPM (≤ 0.07 mm).</span></p>
      
      <p><span>Trong một trường hợp khác, tôi để tiến hành cân chỉnh một hệ turbine – máy phát. &nbsp;Ban đầu, mọi thứ đều diễn ra ổn thỏa. Các bước an toàn đã được hoàn thành cách kỹ lưỡng khi tôi đến đây. Khớp nối và nắp che cũng đã được tháo ra hoàn toàn, máy cũng đã được cân chỉnh thô trước đó. Những miếng căn lót (shims) chuyên dụng cho máy phát điện cũng có sẵn tại hiện trường. Nhưng kể cả những điều kiện như thế, việc cân chỉnh vẫn mất hơn 1.5 ngày&nbsp;để hoàn thành.</span></p>
      
      <p><span>Như vậy yếu tố nào sẽ ảnh hưỡng đến công tác cân chỉnh?</span></p>
      
      <h2><span><strong>VẤN ĐỀ VỀ CHÂN MỀM (SOFT FOOT)</strong></span></h2>
      <p><span>Có những vấn đề phát sinh trong quá trình cân chỉnh không thể lường trước được, một trong số đấy là điều kiện chân mềm (Soft foot). Không kiểm tra chân mềm (Soft foot) có thể làm phát sinh thời gian trong công tác cân chỉnh. Trong trường hợp giá trị chân mềm (Soft foot) ngoài vùng dung sai, kết quả phản hồi từ thiết bị đo sẽ không được chính xác. Do đó, hiểu giá trị chân mềm (Soft foot) và khống chế nó chính là yếu tố quan trọng. Tuy nhiên, tuổi thọ của giá trị này còn tùy thuộc vào điều kiện bệ máy, bu-lông, vòng đệm, ứng suất đường ống…</span></p>
      
      <p><img src="/uploads/shaft_concentric_alignment_c70069be5e.jpg?31315619.4" data-width="504" data-height="284"/></p>
      
      
      <p><span>Trong cả hai trường hợp trên, cả hai máy đều là cân chỉnh khớp nối đơn. Cân chỉnh một hệ máy phát điện cỡ lớn rõ ràng sẽ khó hơn 1 motor 200HP.</span></p>
      <ul>
      <li><span>Trong trường hợp đầu tiên, tôi có thể xoay hệ trục motor – máy bơm dễ dàng.</span></li>
      <li><span>Trong trường hợp thứ hai với máy phát điện, cần tách rời và xoay trục lần lượt từng máy, vì hệ trục chúng quá khó để xoay cùng nhau trong 1 lần.</span></li>
      </ul>
      <p><span>Hầu hết trong các trường hợp, một máy lớn cần nhiều thời gian cân chỉnh hơn. Vì chỉ việc tháo ốc và bu-lông cũng tiêu tốn gần 20-30 phút cho 1 lần. Hơn nữa, vẫn sẽ có những khác biệt lớn giữa tổng thời gian cân chỉnh cho 2 hệ máy giống nhau. Xác định chính xác những thông tin này (chiều dài khớp nối, kích thước bu – lông, kích thước căn lót,…) có thể giúp người dùng giảm thiểu thời gian cân chỉnh máy.</span></p>
      <p><img src="/uploads/shaft_concentric_alignment_c70069be5e.jpg?31315619.4" data-width="504" data-height="284"/></p>
      
      <p><span><strong>Tóm lại, sẽ không có chính xác lượng thời gian cố định cho việc cân chỉnh máy.&nbsp;Nên thận trọng trong suy xét các vấn đề của chân mềm (Soft foot) và kích thước máy để hoạch định thời gian phù hợp.</strong></span></p>
      `,
      slug: "mat-bao-lau-de-can-chinh-mot-he-thong-may",
      desktopBanner: { url: BlogDetailBannerDesktop },
    },
    {
      title: "GIẢI PHÁP KIỂM TRA CÂN CHỈNH ĐỒNG TÂM TRỤC CHO HỆ MÁY NÉN KHÍ",
      shortDescription: `<p>Cân chỉnh đồng tâm trục máy nén khí không thật sự quá phức tạp như chúng ta thường lo ngại.</p>
    <p>Bên cạnh các yêu cầu cơ bản về dung sai nghiêm ngặt do tốc độ vòng quay cao thì thông số giãn nở nhiệt (thermal expansion) là thông số chúng ta cần quan tâm và tính toán khi cân chỉnh. Việc hiểu ý nghĩa của từng thông số giúp chúng ta dễ dàng hơn trong việc cân chỉnh.</p>`,
      published_at: "2023-02-02T15:56:22.000Z",
      type: "NEWS",
      thumbnail: { url: BlogThumbnail },
      author: "Admin",
      content: "",
    },
    {
      title: "SỰ KHÁC NHAU GIỮA DUNG SAI KHỚP NỐI VÀ DUNG SAI CÂN CHỈNH TRỤC",
      shortDescription: `<p>Nếu muốn bảo trì bảo dưỡng máy móc chuẩn xác, kỹ thuật viên cần hiểu rõ sự khác nhau giữa dung sai cân chỉnh trục và dung sai khớp nối. Trong máy quay, lực truyền tải từ máy động sang máy truyền động thông qua khớp nối. Đây là những thành phần trong máy cơ khí phải chịu lực cao từ mã lực, mô-men xoắn, v.v</p>`,
      published_at: "2023-01-01T15:56:22.000Z",
      type: "NEWS",
      thumbnail: { url: BlogThumbnail },
      author: "Admin",
      content: "",
    },
    {
      title: "GIẢI PHÁP KIỂM TRA CÂN CHỈNH ĐỒNG TÂM TRỤC CHO HỆ MÁY NÉN KHÍ",
      shortDescription: `<p>Cân chỉnh đồng tâm trục máy nén khí không thật sự quá phức tạp như chúng ta thường lo ngại.</p>
    <p>Bên cạnh các yêu cầu cơ bản về dung sai nghiêm ngặt do tốc độ vòng quay cao thì thông số giãn nở nhiệt (thermal expansion) là thông số chúng ta cần quan tâm và tính toán khi cân chỉnh. Việc hiểu ý nghĩa của từng thông số giúp chúng ta dễ dàng hơn trong việc cân chỉnh.</p>`,
      published_at: "2023-01-31T15:56:22.000Z",
      type: "NEWS",
      thumbnail: { url: BlogThumbnail },
      author: "Admin",
      content: "",
    },
    {
      title: "GIẢI PHÁP KIỂM TRA CÂN CHỈNH ĐỒNG TÂM TRỤC CHO HỆ MÁY NÉN KHÍ",
      shortDescription: `<p>Cân chỉnh đồng tâm trục máy nén khí không thật sự quá phức tạp như chúng ta thường lo ngại.</p>
    <p>Bên cạnh các yêu cầu cơ bản về dung sai nghiêm ngặt do tốc độ vòng quay cao thì thông số giãn nở nhiệt (thermal expansion) là thông số chúng ta cần quan tâm và tính toán khi cân chỉnh. Việc hiểu ý nghĩa của từng thông số giúp chúng ta dễ dàng hơn trong việc cân chỉnh.</p>`,
      published_at: "2023-01-30T15:56:22.000Z",
      type: "NEWS",
      thumbnail: { url: BlogThumbnail },
      author: "Admin",
      content: "",
    },
    {
      title: "GIẢI PHÁP KIỂM TRA CÂN CHỈNH ĐỒNG TÂM TRỤC CHO HỆ MÁY NÉN KHÍ",
      shortDescription: `<p>Cân chỉnh đồng tâm trục máy nén khí không thật sự quá phức tạp như chúng ta thường lo ngại.</p>
    <p>Bên cạnh các yêu cầu cơ bản về dung sai nghiêm ngặt do tốc độ vòng quay cao thì thông số giãn nở nhiệt (thermal expansion) là thông số chúng ta cần quan tâm và tính toán khi cân chỉnh. Việc hiểu ý nghĩa của từng thông số giúp chúng ta dễ dàng hơn trong việc cân chỉnh.</p>`,
      published_at: "2023-01-29T15:56:22.000Z",
      type: "NEWS",
      thumbnail: { url: BlogThumbnail },
      author: "Admin",
      content: "",
    },
    {
      title: "ĐỊNH TÂM LỖ TRÊN VỎ BƠM",
      shortDescription: `<p>Gần đây chúng tôi đã có một yêu cầu từ vài khách hàng để thực hiện một số dịch vụ cân chỉnh bơm. Thông thường</p>`,
      published_at: "2023-01-28T15:56:22.000Z",
      type: "NEWS",
      thumbnail: { url: BlogThumbnail },
      author: "Admin",
      content: "",
    },
  ],
};

export const Blogs_Mock_Data: BlogDetailCardType[] = [
  {
    title: "MẤT BAO LÂU ĐỂ CÂN CHỈNH MỘT HỆ THỐNG MÁY?",
    shortDescription: `<p>Những người hoạch định thường gặp nhiều khó khăn trong việc phân bổ thời gian cho công tác cân chỉnh đồng tâm trục.</p>`,
    published_at: "2023-02-03T15:56:22.000Z",
    type: "EVENT",
    thumbnail: { url: BlogThumbnail },
    author: "Admin",
    content: `<h3><span><strong>Những người hoạch định thường gặp nhiều khó khăn trong việc phân bổ thời gian cho công tác cân chỉnh đồng tâm trục. Trong nhiều trường hợp, có thể họ sẽ phỏng đoán dựa trên thời gian trung bình đã từng làm trước đó. Mọi chuyện có thật sự đơn giản như thế?</strong></span></h3>

    <p><span>Tôi đã tiến hành cân chỉnh một mô hình motor và máy bơm trong phòng thực hành. Trình bày từng bước để cân chỉnh máy. Thực hiện cân chỉnh theo từng bước an toàn, và đồng thời máy cũng đã được cân chỉnh thô trước đó. Giá trị chân mềm (soft foot) đã trong vùng dung sai. Công việc hoàn thành trong vòng 45 phút, với giá trị dung sai cho phép của tốc độ 1200 RPM (≤ 0.07 mm).</span></p>
    
    <p><span>Trong một trường hợp khác, tôi để tiến hành cân chỉnh một hệ turbine – máy phát. &nbsp;Ban đầu, mọi thứ đều diễn ra ổn thỏa. Các bước an toàn đã được hoàn thành cách kỹ lưỡng khi tôi đến đây. Khớp nối và nắp che cũng đã được tháo ra hoàn toàn, máy cũng đã được cân chỉnh thô trước đó. Những miếng căn lót (shims) chuyên dụng cho máy phát điện cũng có sẵn tại hiện trường. Nhưng kể cả những điều kiện như thế, việc cân chỉnh vẫn mất hơn 1.5 ngày&nbsp;để hoàn thành.</span></p>
    
    <p><span>Như vậy yếu tố nào sẽ ảnh hưỡng đến công tác cân chỉnh?</span></p>
    
    <h2><span><strong>VẤN ĐỀ VỀ CHÂN MỀM (SOFT FOOT)</strong></span></h2>
    <p><span>Có những vấn đề phát sinh trong quá trình cân chỉnh không thể lường trước được, một trong số đấy là điều kiện chân mềm (Soft foot). Không kiểm tra chân mềm (Soft foot) có thể làm phát sinh thời gian trong công tác cân chỉnh. Trong trường hợp giá trị chân mềm (Soft foot) ngoài vùng dung sai, kết quả phản hồi từ thiết bị đo sẽ không được chính xác. Do đó, hiểu giá trị chân mềm (Soft foot) và khống chế nó chính là yếu tố quan trọng. Tuy nhiên, tuổi thọ của giá trị này còn tùy thuộc vào điều kiện bệ máy, bu-lông, vòng đệm, ứng suất đường ống…</span></p>
    
    <p><img src="/uploads/shaft_concentric_alignment_c70069be5e.jpg?31315619.4" data-width="504" data-height="284"/></p>
    
    
    <p><span>Trong cả hai trường hợp trên, cả hai máy đều là cân chỉnh khớp nối đơn. Cân chỉnh một hệ máy phát điện cỡ lớn rõ ràng sẽ khó hơn 1 motor 200HP.</span></p>
    <ul>
    <li><span>Trong trường hợp đầu tiên, tôi có thể xoay hệ trục motor – máy bơm dễ dàng.</span></li>
    <li><span>Trong trường hợp thứ hai với máy phát điện, cần tách rời và xoay trục lần lượt từng máy, vì hệ trục chúng quá khó để xoay cùng nhau trong 1 lần.</span></li>
    </ul>
    <p><span>Hầu hết trong các trường hợp, một máy lớn cần nhiều thời gian cân chỉnh hơn. Vì chỉ việc tháo ốc và bu-lông cũng tiêu tốn gần 20-30 phút cho 1 lần. Hơn nữa, vẫn sẽ có những khác biệt lớn giữa tổng thời gian cân chỉnh cho 2 hệ máy giống nhau. Xác định chính xác những thông tin này (chiều dài khớp nối, kích thước bu – lông, kích thước căn lót,…) có thể giúp người dùng giảm thiểu thời gian cân chỉnh máy.</span></p>
    <p><img src="/uploads/shaft_concentric_alignment_c70069be5e.jpg?31315619.4" data-width="504" data-height="284"/></p>
    
    <p><span><strong>Tóm lại, sẽ không có chính xác lượng thời gian cố định cho việc cân chỉnh máy.&nbsp;Nên thận trọng trong suy xét các vấn đề của chân mềm (Soft foot) và kích thước máy để hoạch định thời gian phù hợp.</strong></span></p>
    `,
    slug: "mat-bao-lau-de-can-chinh-mot-he-thong-may",
    desktopBanner: { url: BlogDetailBannerDesktop },
  },
  {
    title: "GIẢI PHÁP KIỂM TRA CÂN CHỈNH ĐỒNG TÂM TRỤC CHO HỆ MÁY NÉN KHÍ",
    shortDescription: `<p>Cân chỉnh đồng tâm trục máy nén khí không thật sự quá phức tạp như chúng ta thường lo ngại.</p>
  <p>Bên cạnh các yêu cầu cơ bản về dung sai nghiêm ngặt do tốc độ vòng quay cao thì thông số giãn nở nhiệt (thermal expansion) là thông số chúng ta cần quan tâm và tính toán khi cân chỉnh. Việc hiểu ý nghĩa của từng thông số giúp chúng ta dễ dàng hơn trong việc cân chỉnh.</p>`,
    published_at: "2023-02-02T15:56:22.000Z",
    type: "NEWS",
    thumbnail: { url: BlogThumbnail },
    author: "Admin",
    content: "",
  },
  {
    title: "SỰ KHÁC NHAU GIỮA DUNG SAI KHỚP NỐI VÀ DUNG SAI CÂN CHỈNH TRỤC",
    shortDescription: `<p>Nếu muốn bảo trì bảo dưỡng máy móc chuẩn xác, kỹ thuật viên cần hiểu rõ sự khác nhau giữa dung sai cân chỉnh trục và dung sai khớp nối. Trong máy quay, lực truyền tải từ máy động sang máy truyền động thông qua khớp nối. Đây là những thành phần trong máy cơ khí phải chịu lực cao từ mã lực, mô-men xoắn, v.v</p>`,
    published_at: "2023-01-01T15:56:22.000Z",
    type: "NEWS",
    thumbnail: { url: BlogThumbnail },
    author: "Admin",
    content: "",
  },
  {
    title: "GIẢI PHÁP KIỂM TRA CÂN CHỈNH ĐỒNG TÂM TRỤC CHO HỆ MÁY NÉN KHÍ",
    shortDescription: `<p>Cân chỉnh đồng tâm trục máy nén khí không thật sự quá phức tạp như chúng ta thường lo ngại.</p>
  <p>Bên cạnh các yêu cầu cơ bản về dung sai nghiêm ngặt do tốc độ vòng quay cao thì thông số giãn nở nhiệt (thermal expansion) là thông số chúng ta cần quan tâm và tính toán khi cân chỉnh. Việc hiểu ý nghĩa của từng thông số giúp chúng ta dễ dàng hơn trong việc cân chỉnh.</p>`,
    published_at: "2023-01-31T15:56:22.000Z",
    type: "NEWS",
    thumbnail: { url: BlogThumbnail },
    author: "Admin",
    content: "",
  },
  {
    title: "GIẢI PHÁP KIỂM TRA CÂN CHỈNH ĐỒNG TÂM TRỤC CHO HỆ MÁY NÉN KHÍ",
    shortDescription: `<p>Cân chỉnh đồng tâm trục máy nén khí không thật sự quá phức tạp như chúng ta thường lo ngại.</p>
  <p>Bên cạnh các yêu cầu cơ bản về dung sai nghiêm ngặt do tốc độ vòng quay cao thì thông số giãn nở nhiệt (thermal expansion) là thông số chúng ta cần quan tâm và tính toán khi cân chỉnh. Việc hiểu ý nghĩa của từng thông số giúp chúng ta dễ dàng hơn trong việc cân chỉnh.</p>`,
    published_at: "2023-01-30T15:56:22.000Z",
    type: "NEWS",
    thumbnail: { url: BlogThumbnail },
    author: "Admin",
    content: "",
  },
  {
    title: "GIẢI PHÁP KIỂM TRA CÂN CHỈNH ĐỒNG TÂM TRỤC CHO HỆ MÁY NÉN KHÍ",
    shortDescription: `<p>Cân chỉnh đồng tâm trục máy nén khí không thật sự quá phức tạp như chúng ta thường lo ngại.</p>
  <p>Bên cạnh các yêu cầu cơ bản về dung sai nghiêm ngặt do tốc độ vòng quay cao thì thông số giãn nở nhiệt (thermal expansion) là thông số chúng ta cần quan tâm và tính toán khi cân chỉnh. Việc hiểu ý nghĩa của từng thông số giúp chúng ta dễ dàng hơn trong việc cân chỉnh.</p>`,
    published_at: "2023-01-29T15:56:22.000Z",
    type: "NEWS",
    thumbnail: { url: BlogThumbnail },
    author: "Admin",
    content: "",
  },
  {
    title: "ĐỊNH TÂM LỖ TRÊN VỎ BƠM",
    shortDescription: `<p>Gần đây chúng tôi đã có một yêu cầu từ vài khách hàng để thực hiện một số dịch vụ cân chỉnh bơm. Thông thường</p>`,
    published_at: "2023-01-28T15:56:22.000Z",
    type: "NEWS",
    thumbnail: { url: BlogThumbnail },
    author: "Admin",
    content: "",
  },
];

import React from "react";

import NImage from "libs/image/NImage";

import ZaloIcon from "assets/images/icons/zalo-icon.png";

import styles from "./Sticky.module.scss";

export interface StickyProps {
  phoneNumber?: number;
}

const Sticky: React.FC<StickyProps> = (props) => {
  return (
    <div className={styles["root"]}>
      <a className={styles["icon-wrapper"]} href={`https://zalo.me/${props?.phoneNumber}`} target="_blank" rel="noopener noreferrer">
        <NImage src={ZaloIcon} alt="Zalo Icon" width={48} height={48} />
      </a>
      <div className={styles["wave"]}></div>
    </div>
  );
};

export default Sticky;

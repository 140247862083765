import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import Search from "components/search/Search";
import { ScrollTo } from "libs/animation/ScrollTo";
import NImage from "libs/image/NImage";
import { useGetLanguage, useLocalize, useScreenWidth } from "libs/redux-common";

import EasylaserLogo from "assets/images/icons/easy-laser_logo.png";
import { ReactComponent as GoTop } from "assets/images/icons/go-top.svg";
import Logo from "assets/images/icons/logo.png";

import LanguageSelect from "./language-select/LanguageSelect";
import Sticky from "./sticky/Sticky";
import TopNav from "./top-nav/TopNav";

import styles from "./TopHeader.module.scss";

export interface TopHeaderProps {}

const TopHeader: React.FC<TopHeaderProps> = (props) => {
  const { data } = useLocalize<any>("common.commonText");
  const [showGoTop, setShowGoTop] = useState(false);
  const history = useHistory();
  const storeLang = useGetLanguage();
  const isDesktopL = useScreenWidth() >= 1536;

  const search = (keyword: string) => {
    history.replace(`/${storeLang === "en" ? "search" : "tim-kiem"}?key=${keyword}`);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 1000) setShowGoTop(true);
      else setShowGoTop(false);
    };
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.addEventListener("scroll", handleScroll);
    };
  }, [showGoTop]);

  return (
    <div className={styles["root"]}>
      <Link to="/" className={styles["logo-wrapper"]}>
        <NImage src={Logo} alt="Logo" width={193} height={80} />
      </Link>
      <TopNav />

      <div className={styles["menu-right"]}>
        <div className={styles["easylaser-logo"]}>
          <NImage src={EasylaserLogo} alt="Logo" width={237} height={57} />
        </div>
        <Search onSearch={search} />
        {isDesktopL && <LanguageSelect />}
      </div>

      <div className={styles["sticky-buttons"]}>
        {showGoTop && <GoTop className={classNames(styles["sticky-button"], styles["gotop-btn"], { "opa-0": !showGoTop })} onClick={() => ScrollTo(window, { y: 0 })} />}
      </div>
      <Sticky phoneNumber={data?.["zalo"]} />
    </div>
  );
};

export default TopHeader;

import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

import { isAbsoluteUrl } from "libs/react-common";
import { notifyAction_Set, useLocalize, useNotify } from "libs/redux-common";

import { ReactComponent as Arrow } from "assets/images/icons/arrow.svg";
import { ReactComponent as HambugerIcon } from "assets/images/icons/hamburger.svg";
import { ReactComponent as XIcon } from "assets/images/icons/x-cross.svg";

import LanguageSelect from "../top-header/language-select/LanguageSelect";

import styles from "./SideMenu.module.scss";

export interface SideLinkItemType {
  label: string;
  url: string;
  onLinkClick?: Function;
}

export interface SideLinkDataType extends SideLinkItemType {
  child?: SideLinkDataType[];
  class?: string;
}

export interface SideHeaderProps {}

const SideMenu: React.FC<SideHeaderProps> = (props) => {
  const { data } = useLocalize<SideLinkDataType[]>("common.header");

  const [showSide, setShowSide] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(notifyAction_Set("side-menu-show", showSide));
  }, [showSide, dispatch]);

  return (
    <div className={styles["root"]}>
      <div className={styles["side-header"]}>
        {showSide ? (
          <XIcon className={styles["hamburger"]} onClick={() => setShowSide(!showSide)} />
        ) : (
          <HambugerIcon className={styles["hamburger"]} onClick={() => setShowSide(!showSide)} />
        )}
      </div>
      <div className={styles["side-menu-wrapper"]}>
        <div className={classNames(styles["side-menu"], { [styles["side-menu--open"]]: showSide })}>
          <div className={styles["side-content"]}>
            <>
              {data?.map((link, index) =>
                link.child ? (
                  <SideLinkGroup {...link} key={link.label} onLinkClick={() => setShowSide(false)} />
                ) : (
                  <SideLinkItem {...link} key={`${link.url}-${index}`} onLinkClick={() => setShowSide(false)} />
                )
              )}
            </>
          </div>

          <LanguageSelect />
        </div>
      </div>
    </div>
  );
};

export default SideMenu;

const SideLinkGroup: React.FC<SideLinkDataType> = (props) => {
  const { label, child, url, onLinkClick } = props;
  const [opened, setOpened] = useState(false);

  const sideOpen = useNotify<boolean>("side-menu-show");

  const location = useLocation().pathname.split("/")[1];
  const isActivated = url ? url.split("/")[1] === location : child && child[0].url.split("/")[1] === location;

  useEffect(() => {
    if (!sideOpen) setOpened(false);
  }, [sideOpen]);

  return (
    <div className={styles["side-link-group"]}>
      <div
        className={classNames(
          styles["side-link__item"],
          styles["side-link__item-group-label"],
          { [styles["side-link__item-group-label--expand"]]: opened },
          { [styles["side-link__item--selected"]]: isActivated }
        )}
        onClick={() => setOpened(!opened)}
      >
        {label}
        <Arrow className={classNames(styles["side-link__item-arrow"], { [styles["side-link__item-arrow--expand"]]: opened })} />
      </div>
      <div className={classNames(styles["side-link__item-group"], { [styles["side-link__item-group_open"]]: opened })}>
        <div className={styles["side-link__item-group-container"]}>
          {child?.map((item) => (
            <SideLinkItem key={item.url || item.label} {...item} onLinkClick={onLinkClick} />
          ))}
        </div>
      </div>
    </div>
  );
};

const SideLinkItem: React.FC<SideLinkItemType> = (props) => {
  const { url, label, onLinkClick } = props;
  const absolute = isAbsoluteUrl(url);

  return (
    <div className={styles["side-link__item"]} onClick={() => onLinkClick && onLinkClick()}>
      {absolute ? (
        <a href={url}>{label}</a>
      ) : (
        <NavLink exact to={props.url || "/"} activeClassName={styles["side-link__item--selected"]}>
          {label}
        </NavLink>
      )}
    </div>
  );
};

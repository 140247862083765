import HTMLReactParser, { HTMLReactParserOptions } from "html-react-parser";
import { HTMLAttributes } from "react";

export interface HtmlRenderProps {
  component?: keyof JSX.IntrinsicElements | "<>";
  content: string | undefined;
}

export const HtmlParser = (content: string | undefined, options?: HTMLReactParserOptions | undefined) => {
  return content ? HTMLReactParser(content, options) : null;
};

const HtmlRender: React.FC<HtmlRenderProps & HTMLAttributes<any>> = (props) => {
  const { component = "div", content, ...others } = props;
  const Component = component;

  return Component !== "<>" ? <Component {...others}>{HTMLReactParser(content || "")}</Component> : <>{HTMLReactParser(content || "")}</>;
};
export default HtmlRender;

export const MetaTagRender = (content: string | undefined) => {
  return content ? HTMLReactParser(content) : "";
};

import { ProdRelatedDataType } from "pages/product-detail/prod-related/ProdRelated";
import { ProductCardDataType } from "pages/product/product-body/ProductBody";

import ProductE910 from "assets/images/product/E910.png";

export const Related_Prod_Mock: ProdRelatedDataType = {
  title: "Related Product",
  description: "Lorem ipsum",
};

export const Related_Prod_List_Mock: ProductCardDataType[] = [
  {
    name: "E910 - Flange",
    shortDescription:
      "Easy-Laser® E910 Flange is a system used to measure flange flatness of windmill towers, wings and hubs, tower bases, etc. It can also be used for parallelism measurement.",
    slug: "e910-flange",
    image: { url: ProductE910 },
  },
  {
    name: "E910 - Flange",
    shortDescription:
      "Easy-Laser® E910 Flange is a system used to measure flange flatness of windmill towers, wings and hubs, tower bases, etc. It can also be used for parallelism measurement.",
    slug: "e910-flange",
    image: { url: ProductE910 },
  },
  {
    name: "E910 - Flange",
    shortDescription:
      "Easy-Laser® E910 Flange is a system used to measure flange flatness of windmill towers, wings and hubs, tower bases, etc. It can also be used for parallelism measurement.",
    slug: "e910-flange",
    image: { url: ProductE910 },
  },
];

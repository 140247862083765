import { isEqual } from "lodash";
import { useEffect, useRef } from "react";

// useEffect with support for compare dependencies
export const useEffectMemo = (callback: React.EffectCallback, dependencies: React.DependencyList) => {
  const ref = useRef<React.DependencyList>();
  if (!ref.current || !isEqual(dependencies, ref.current)) {
    ref.current = dependencies;
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, ref.current);
};
export default useEffectMemo;

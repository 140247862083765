import { NotifyActionType } from "../actions/notifyAction";

export enum NotifyAction {
  NOTIFY = "NOTIFY",
}

export type NotifyActionDataType = number | boolean | string;

// action state type define
export interface NotifyActionState {
  [key: string]: NotifyActionDataType;
}

// action initial state
const initialState: NotifyActionState = {};

const notifyReducer = (state: NotifyActionState = initialState, action: NotifyActionType): NotifyActionState => {
  switch (action.type) {
    case NotifyAction.NOTIFY:
      return { ...state, [action.payload.event]: action.payload.data };
    default:
      return state;
  }
};

export default notifyReducer;

import { attempt, isError } from "lodash";

export const getMimeType = (ext: string | undefined): "video" | "image" | undefined => {
  const MIME_TYPE = {
    "3gpp": "video",
    "3gp": "video",
    ts: "video",
    mp4: "video",
    mpeg: "video",
    mpg: "video",
    mov: "video",
    webm: "video",
    flv: "video",
    m4v: "video",
    mng: "video",
    asx: "video",
    asf: "video",
    wmv: "video",
    avi: "video",
    gif: "image",
    jpeg: "image",
    jpg: "image",
    png: "image",
    svg: "image",
    svgz: "image",
    tif: "image",
    tiff: "image",
    wbmp: "image",
    webp: "image",
    ico: "image",
    jng: "image",
    bmp: "image",
  };
  return ext ? (MIME_TYPE as any)[ext] : undefined;
};

export const clampText = (text: string | undefined, maxLength: number): string => {
  if (!text) return "";
  if (text.length < maxLength) return text;

  let result = text.slice(0, maxLength).trim();
  if (
    result
      .split("")
      .pop()!
      .search(/[^a-zA-Z0-9]/) > -1
  )
    result = result.slice(0, -1);

  return result.trim() + "...";
};

export const shortenNumber = (fullNumber: number | undefined) => {
  if (typeof fullNumber !== "number") return;
  const abbrs: { [key: string]: number } = { b: 1e9, m: 1e6, k: 1e3 };
  for (let key in abbrs) {
    if (fullNumber >= abbrs[key]) return (fullNumber / abbrs[key]).toString().match(/[0-9]*.[0-9]{1}|[0-9]/) + key;
  }
  return fullNumber;
};

export const safeParseJSON = (json?: string) => {
  if (!json) return json;
  const result = attempt(JSON.parse, json);
  return isError(result) ? json : result;
};

export const formatDate = (date: string = "", locale: string = "en", useUTCTime: boolean = false) => {
  const _date = new Date(date);
  const utcDate = new Date(_date.getTime() + _date.getTimezoneOffset() * 60000);
  return _date.toString() !== "Invalid Date" ? new Intl.DateTimeFormat(locale, { month: "long", day: "numeric", year: "numeric" }).format(useUTCTime ? utcDate : _date) : "";
};

export const isAbsoluteUrl = (url: string | undefined): boolean => {
  if (!url) return false;
  // Don't match Windows paths `c:\`
  if (/^[a-zA-Z]:\\/.test(url)) {
    return false;
  }

  // Scheme: https://tools.ietf.org/html/rfc3986#section-3.1
  // Absolute URL: https://tools.ietf.org/html/rfc3986#section-4.3
  return /^[a-zA-Z][a-zA-Z\d+\-.]*:/.test(url);
};

import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";

import { ErrorAction, errorReducer } from "libs/redux-common";
import localizeReducer, { LocalizeAction } from "libs/redux-common/reducers/localizeReducer";
import notifyReducer, { NotifyAction } from "libs/redux-common/reducers/notifyReducer";
import systemReducer, { SystemAction } from "libs/redux-common/reducers/systemReducer";

const rootReducers = combineReducers({
  error: errorReducer,
  localize: localizeReducer,
  notify: notifyReducer,
  system: systemReducer,
});

export type StoreType = ReturnType<typeof rootReducers>;

const store = createStore(rootReducers, applyMiddleware(thunk));
export default store;

const dispatch = store.dispatch;
export { dispatch };
// need to extend all action type here
type ActionBaseType = ErrorAction | LocalizeAction | NotifyAction | SystemAction;

export interface IBaseAction {
  type: ActionBaseType;
}

import classNames from "classnames";
import parse, { attributesToProps, HTMLReactParserOptions } from "html-react-parser";
import React from "react";

import HtmlRender from "libs/html-render/HtmlRender";
import NImage from "libs/image/NImage";
import { useLocalize } from "libs/redux-common";
import { APP_API } from "utils/constants";

import Logo from "assets/images/icons/logo.png";

import styles from "./Footer.module.scss";

export interface FooterDataType {
  leftContent: string;
  rightContent: string;
}

export interface FooterProps {}

export const parseOptions: HTMLReactParserOptions = {
  replace: (domNode: any) => {
    if (domNode.type === "tag" && (domNode.name === "img" || domNode.name === "video")) {
      const props: { src: string; srcSet?: string; alt?: string; "data-width": number; "data-height": number } = attributesToProps(domNode.attribs) as any;

      const { "data-width": dataWidth, "data-height": dataHeight, srcSet, ...finalProps } = props;

      // let newSrcSet: string[] = [];
      // srcSet?.split(",").forEach((item, idx) => {
      //   newSrcSet.push(`${APP_API}${item}`);
      // });
      // newSrcSet?.pop();
      // const newSrcSetString = newSrcSet.toString();

      return (
        <NImage
          width={props["data-width"]}
          height={props["data-height"]}
          alt={finalProps.alt || "VLA"}
          {...finalProps}
          src={`${APP_API}${props.src}`}
          // srcSet={newSrcSetString}
          videoProps={{ disableRemotePlayback: false }}
        />
      );
    }
  },
};

const Footer: React.FC<FooterProps> = (props) => {
  const { data } = useLocalize<FooterDataType>("common.footer");

  return (
    <div className={`${styles["root"]} footer`}>
      <div className={styles["content-wrapper"]}>
        <div>
          <div className={styles["logo-wrapper"]}>
            <NImage src={Logo} alt="Logo" width={193} height={80} />
          </div>
          <div className={styles["left-content"]}>{parse(data?.leftContent || "", parseOptions)}</div>
        </div>
        <HtmlRender content={data?.rightContent} />
      </div>

      <div className={classNames(styles["copyright"], "text-body1")}>{`© ${new Date().getFullYear()} Copyright Easylaser.vn. All Rights Reserved`}</div>
    </div>
  );
};

export default Footer;

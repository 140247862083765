import { ContactUsDataType } from "pages/contact-us/ContactUs";

import DesktopBanner from "assets/images/contact-us/Contact-desktop-banner.jpg";

export const ContactUs_Mock_Data: ContactUsDataType = {
  bannerTitle: `NHÀ CUNG CẤP ĐỘC QUYỀN EASY-LASER® TẠI VIỆT NAM`,
  bannerDescription:
    "Hiện nay, Easy-Laser® đã có mặt ở hơn 70 quốc gia. VLA được thận trọng lựa chọn làm nhà cung cấp độc quyền của hệ thống Easy-Laser tại Việt Nam. Chúng tôi hiểu về nhu cầu và từng sản phẩm để đem đến các giải pháp tốt nhất cho các nhà máy, giúp giảm thiểu chi phí bảo trì bảo dưỡng. Chúng tôi luôn được đào tạo về sản phẩm và ứng dụng thường xuyên từ phía công ty tại Thụy Điển.",
  title: "Vui lòng liên hệ với chúng tôi để biết thêm thông tin về các hệ thống đo lường và căn chỉnh Easy-Laser® tại Việt Nam",
  description:
    "Quisque velit nisi, pretium ut lacinia in, elementum id enim. Sed porttitor lectus nibh. Vivamus suscipit tortor eget felis porttitor volutpat. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae;",
  bannerDesktopBg: { url: DesktopBanner },
  formFields: [
    {
      label: "Fullname*",
      name: "fullname",
      type: "input",
      placeholder: "Enter your fullname",
      constraints: {
        required: true,
      },
      errors: {
        required: "The Fullname is required",
      },
    },
    {
      label: "Email*",
      name: "email",
      type: "input",
      placeholder: "Enter your Email",
      constraints: {
        required: true,
        pattern: "^[\\w._%+-]+@[\\w.-]+.[\\w]{2,4}$",
      },
      errors: {
        required: "Email is required",
        pattern: "Invalid email",
      },
    },
    {
      label: "Phone Number",
      name: "phone",
      type: "input",
      placeholder: "Enter your phone number",
      constraints: {
        required: true,
        pattern: `^[0-9\\-\\+]{9,15}$`,
      },
      errors: {
        required: "Phone number is required",
        pattern: "Invalid phone number",
      },
    },
    {
      label: "Company",
      name: "company",
      type: "input",
      placeholder: "Enter your company",
    },
    {
      label: "Agency",
      name: "agency",
      type: "input",
      placeholder: "Enter your agency",
    },
    {
      label: "Content",
      name: "content",
      type: "input",
      placeholder: "Enter your content",
      multiple: 5,
    },
  ],
  mapsLink: "https://maps.google.com/maps?q=16%20Y%C3%AAn%20Th%E1%BA%BF%2C%20T%C3%A2n%20B%C3%ACnh&t=m&z=15&output=embed&iwloc=near",
  popupSuccessContent: {
    title: "Thank You!",
    description: "We will contact you when we as soon as we can",
    btnText: "OK",
  },
  popupFailedContent: {
    title: "Aww! Sorry!",
    description: "The server is cannot serve you now, Please try again.",
    btnText: "Try again",
  },
};

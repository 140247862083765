import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";

import useClickOutside from "libs/react-hooks/useClickOutSide";

import { ReactComponent as SearchClear } from "assets/images/icons/close-circle.svg";
import { ReactComponent as SearchIcon } from "assets/images/icons/search-icon.svg";

import styles from "./Search.module.scss";

export interface SearchDataType {}

export interface SearchProps {
  placeholder?: string;
  onSearch?: (value: string) => void;
}

const Search: React.FC<SearchProps> = (props) => {
  const { placeholder, onSearch } = props;
  const rootRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const wrapperInputRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState("");
  const [inputHide, setInputHide] = useState(true);

  useClickOutside(rootRef, () => {
    setInputHide(true);
  });

  const search = () => {
    setInputHide(true);
    onSearch?.(value);
    setValue("");
    inputRef.current && (inputRef.current.value = "");
  };

  const clear = () => {
    setValue("");
    inputRef.current && (inputRef.current.value = "");
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const { styles: popperStyles, attributes: popperAttr } = usePopper(rootRef.current, wrapperInputRef.current, {
    placement: "bottom",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 30],
        },
      },
    ],
  });

  useEffect(() => {
    if (!inputHide) inputRef.current?.focus();
  }, [inputHide]);

  return (
    <div className={styles["root"]}>
      <div className={styles["search-form"]} ref={rootRef}>
        <div className={classNames(styles["icon"], { [styles["icon-focus"]]: !inputHide && value === "" })}>
          <SearchIcon
            onClick={() => {
              !inputHide && value !== "" && search();
              setInputHide(!inputHide);
            }}
          />
        </div>
        <div className={styles["search-input"]} style={popperStyles.popper} {...popperAttr.popper} ref={wrapperInputRef}>
          <input
            ref={inputRef}
            type="text"
            value={value}
            onChange={onChange}
            placeholder={!inputHide ? placeholder : ""}
            maxLength={200}
            onKeyDown={(e) => e.key === "Enter" && value && search()}
            className={classNames(styles["input"], { [styles["input-hide"]]: inputHide })}
          />
          {!inputHide && <SearchClear className={styles["clear"]} style={{ display: value ? "block" : "none" }} onClick={clear} />}
        </div>
      </div>
    </div>
  );
};

export default Search;

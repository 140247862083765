import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { ScrollTo } from "libs/animation/ScrollTo";
import { useLocalize } from "libs/redux-common";

import styles from "./PageNotFound.module.scss";

export interface PageNotFoundProps {
  textBackToHome?: string;
  textNotFound?: string;
  link?: string;
}

const PageNotFound: React.FC<PageNotFoundProps> = (props) => {
  const { textBackToHome, textNotFound, link } = props;
  const { data: commonText } = useLocalize("common.commonText");

  useEffect(() => {
    ScrollTo(window, { y: 0 });
  }, []);

  return (
    <div className={styles["root"]}>
      <Link to={link ?? "/"} className={styles["back-home"]}>
        ← {textBackToHome || commonText?.["back-to-home"] || "Quay về Trang chủ"}
      </Link>
      <div className={styles["text"]}>
        <h1>{textNotFound || commonText?.["page-not-found"] || "Không tìm thấy trang"}</h1>
      </div>
    </div>
  );
};

export default PageNotFound;

import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";

import { useEffectMemo } from "libs/react-hooks";
import useClickOutside from "libs/react-hooks/useClickOutSide";
import { useGetLanguage } from "libs/redux-common";

import { ReactComponent as Arrow } from "assets/images/icons/arrow.svg";

import styles from "./LanguageSelect.module.scss";

export interface LanguageDataType {
  code: string;
  icon?: SImage;
  name: string;
}

export interface LanguageManagerDataType {
  languages: LanguageDataType[];
}

export interface LanguageSelectProps {
  classes?: {
    root?: string;
    selected?: string;
    options?: string;
    option?: string;
  };
}
const LANGUAGES: LanguageDataType[] = [
  {
    code: "en",
    name: "ENGLISH",
  },
  {
    code: "vi",
    name: "TIẾNG VIỆT",
  },
];

const LanguageSelect: React.FC<LanguageSelectProps> = (props) => {
  const [expand, setExpand] = useState<boolean>(false);
  const wrapperRef = useRef(null);
  const storeLang = useGetLanguage();
  const [selected, setSelected] = useState<LanguageDataType>({ code: storeLang, name: "" });
  const [currentLang, setCurrentLang] = useState<string>(storeLang); // init state is default language

  useClickOutside(wrapperRef, () => setExpand(false));

  // update to sync with store lang when it update
  useEffect(() => {
    storeLang && setCurrentLang(storeLang);
  }, [storeLang]);

  // update store lang when select new
  useEffect(() => {
    if (currentLang && storeLang !== currentLang) {
      // save prefer language when change
      window._app.setLanguage(currentLang);
      window.location.href = "/";
    }
  }, [currentLang, storeLang]);

  // sync current language with save language
  useEffectMemo(() => {
    const saveLang = window._app.getLanguage();

    // update select lang
    if (LANGUAGES && saveLang) {
      let langData = LANGUAGES.find((lang) => lang.code === saveLang);
      langData && setSelected(langData);
    }
  }, []);

  const renderValue = (value: LanguageDataType | undefined) => <div className={classNames(styles["language-selected"], "s:text-subtitle")}>{value?.code || ""}</div>;

  const renderOptions = (option: LanguageDataType) => {
    if (option?.code === selected?.code) return;
    const name = option?.name || "";

    return (
      <div className={styles["language-option"]}>
        <div className={classNames(styles["language-option-text"], "text-body2")}>{name}</div>
      </div>
    );
  };

  return (
    <div className={classNames(styles["root"], props.classes?.root)} ref={wrapperRef}>
      {LANGUAGES && LANGUAGES.length > 0 && (
        <div className={styles["container"]}>
          <div className={classNames(styles["selected-value"], props.classes?.selected)} onClick={() => setExpand(!expand)}>
            {renderValue(selected)}
            <Arrow className={classNames(styles["arrow"], { [styles["arrow--open"]]: expand })} />
          </div>
          <div className={classNames(styles["dropdown"], { [styles["dropdown--expand"]]: expand }, props.classes?.options)}>
            <div className={styles["options"]}>
              {LANGUAGES?.map((lang, index) => {
                const isSelected = lang.code === selected?.code;
                return (
                  <div
                    key={index}
                    className={classNames(styles["option"], { [styles["option--clickable"]]: expand }, { [styles["option--active"]]: isSelected }, props.classes?.option)}
                    onClick={() => {
                      setCurrentLang(lang.code);
                      setExpand(false);
                    }}
                  >
                    {renderOptions(lang)}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSelect;

import axios, { CancelTokenSource } from "axios";
import { cloneDeep, flatten, get, isArray, remove, unset } from "lodash";
import { useCallback, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { ThunkAction } from "redux-thunk";

import { useEffectMemo } from "../../react-hooks";
import { ActionTypeCommon, StoreTypeCommon } from "../index";
import { LocalizeAction } from "../reducers/localizeReducer";

// inject funcs

let _mockData: Record<string, any> | undefined = undefined;
export const setLocalizeMockData = (data?: Record<string, any>) => {
  _mockData = data;
};

// action type define

export interface LocalizeAction_SetData extends ActionTypeCommon {
  type: LocalizeAction.SET_DATA;
  payload: {
    storePath: string;
    lang?: string; // default will be active lang
    data: any;
  };
}

export interface LocalizeAction_ChangeLanguage extends ActionTypeCommon {
  type: LocalizeAction.CHANGE_LANGUAGE;
  payload: {
    language: string;
  };
}

export interface LocalizeAction_Clear extends ActionTypeCommon {
  type: LocalizeAction.CLEAR;
  payload: {
    storePath: string;
  };
}

export type LocalizeActionType = LocalizeAction_SetData | LocalizeAction_ChangeLanguage | LocalizeAction_Clear; // | any other action;

// action creator

export const localizeAction_changeLanguage = (language: string): LocalizeAction_ChangeLanguage => {
  return {
    type: LocalizeAction.CHANGE_LANGUAGE,
    payload: {
      language,
    },
  };
};

export const localizeAction_setData =
  (storePath: string, lang: string, options: LocalizeOptionsType): ThunkAction<void, StoreTypeCommon, undefined, LocalizeActionType> =>
  async (dispatch) => {
    const apiUrl = typeof options === "string" ? options : options.api;
    const key = cacheKey(storePath, !!apiUrl);
    const cache = __cache[key];
    if (!cache) return;
    let data = await getLocalizeData(options);
    // update cache status when end
    if (!data) {
      if (cache.retry < cache.maxRetry - 1) cache.status = "retry";
      else cache.status = "error";
    } else cache.status = "done";

    const selector = typeof options === "object" ? options.selector : undefined;
    if (data && selector) data = get(data, selector);
    if (typeof options === "object" && options.resolvedData) data = options.resolvedData(cloneDeep(data));

    dispatch({
      type: LocalizeAction.SET_DATA,
      payload: {
        storePath,
        lang,
        data,
      },
    });
  };

export const localizeAction_Clear = (storePath: string): LocalizeAction_Clear => {
  return {
    type: LocalizeAction.CLEAR,
    payload: {
      storePath,
    },
  };
};

//////////////////////////// helper api ///////////////////////////////

export const getLocalizeData = async (options: LocalizeOptionsType) => {
  const useGet: boolean = typeof options === "string" || options.method !== "POST";
  const apiPath = typeof options === "string" ? options : options.api;
  const response = useGet
    ? await window._app.api.get(apiPath, { ...(options as LocalizeOptions).apiConfig })
    : await window._app.api.post(apiPath, { ...(options as LocalizeOptions).postData }, { ...(options as LocalizeOptions).apiConfig });

  const error = !response.data || response.data.error !== undefined || response.data.errors !== undefined;
  // use mock if available && request failed
  if (error && _mockData) {
    const data = _mockData?.[(options as LocalizeOptions).mockPath || apiPath];
    return data;
  }

  // otherwise return data if it ok
  return !error ? response.data : undefined;
};

//////////////////////////// helper hooks //////////////////////////////
type LocalizeReturnStatus = "idle" | "fetching" | "error" | "done" | "retry";
type LocalizeOptions<T = any> = {
  api: string;
  method?: "GET" | "POST";
  postData?: {};
  apiConfig?: {};
  mockPath?: string;
  selector?: string;
  enable?: boolean;
  maxRetry?: number;
  resolvedData?: (data: any) => T;
};
type LocalizeOptionsType<T = any> = LocalizeOptions<T> | string;
type LocalizeReturnType<T> = {
  data: T | undefined;
  status: LocalizeReturnStatus;
};

export const useLanguage = (language?: string) => {
  const store = useStore<StoreTypeCommon>().getState();
  const dispatch = useDispatch();
  if (!language) {
    const saveLang = window._app.getLanguage();
    language = saveLang;
  }

  if (language && store.localize.language !== language) {
    // save to local
    window._app.setLanguage(language);
    // dispatch change
    setTimeout(() => dispatch(localizeAction_changeLanguage(language!)), 0);
  }
};

export const useGetLanguage = () => {
  const storeLang = useSelector<StoreTypeCommon, string>((store) => store.localize.language);
  return storeLang;
};

export const setLocalizeLang = (language: string) => {
  const store = window._app.store;
  if (!store) return;

  const dispatch = store.dispatch;
  const storeState = store.getState();

  if (language && storeState.localize && storeState.localize.language !== language) {
    // save to local
    window._app.setLanguage(language);
    // dispatch change
    setTimeout(() => dispatch(localizeAction_changeLanguage(language!)), 0);
  }
};

export const useLocalize = <T = any>(storePath: string, options: LocalizeOptionsType<T> = ""): LocalizeReturnType<T> => {
  const absPath = storePath.startsWith(".");
  const path = absPath ? storePath.substr(1) : storePath;
  const apiUrl = typeof options === "string" ? options : options.api;
  const key = cacheKey(storePath, !!apiUrl);
  const maxRetry = typeof options === "string" ? 3 : options.maxRetry || 3; // default retry times: 3
  const enable = typeof options === "string" ? true : options.enable !== undefined ? options.enable : true;

  const cache = __cache[key] || {};

  // select lang from store so whenever lang change it will notify this to check again
  const lang = useSelector<StoreTypeCommon, string>((store) => store.localize.language);
  // init cache
  if (
    apiUrl && // cache init for request with api only
    (!__cache[key] || // cache not exist
      (cache.status === "done" && cache.api && cache.api !== apiUrl) || // done but api change
      (cache.status === "error" && cache.api && cache.api !== apiUrl) || // error but api change
      (cache.status === "fetching" && cache.api && cache.api !== apiUrl)) // fetching but api change
  ) {
    if (cache.status === "fetching") cache.cancelToken.cancel("localize cancel");
    cache.status = "idle";
    cache.api = apiUrl;
    cache.retry = 0;
    cache.maxRetry = maxRetry;
    cache.cancelToken = axios.CancelToken.source();
    __cache[key] = cache;
  }

  // select from store
  const resultData: T = useSelector<StoreTypeCommon, any>((store) => {
    if (cache.status === "error" || cache.status === "retry") return { __error: Date.now() }; // return obj to force comp re-render
    if (!apiUrl || cache.status === "done") {
      let result = absPath ? get(store.localize.data, path) : get(store.localize.active, path);
      if (cache.status === "done") result = result || { __error: 1 }; // incase selector/resolveData return undefined, then we should update the state by return obj
      return result;
    }
    return undefined;
  });

  const dispatch = useDispatch();

  if (apiUrl && enable && lang)
    setTimeout(() => {
      if (
        (!resultData || (resultData as any)?.__error) && // there have no data
        (cache.status === "idle" || // idle state
          (cache.status === "retry" && cache.retry < maxRetry - 1)) // retry not exceed
      ) {
        if (cache.status === "retry") cache.retry += 1;
        cache.status = "fetching";
        cache.requestOptions = options;
        dispatch(localizeAction_setData(storePath, lang, options));
      }
    }, 0);

  return {
    data: !apiUrl || cache.status === "done" ? ((resultData as any)?.__error ? undefined : resultData) : undefined,
    status: cache.status,
  };
};

type LocalizePageReturnType<T> = {
  latestPage: T[] | undefined;
  allPages: [T[]] | undefined;
  currentPage: T[] | undefined;
  data: T[] | undefined;
  config: LocalizePageConfig | undefined;
  fetchNextPage: () => void;
  fetchPrevPage: () => void;
  fetchPage: (page: number) => void;
  reset: () => void;
  hasNext: boolean;
  hasPrev: boolean;
  status: LocalizeReturnStatus;
};
type LocalizePageOptions<T = any> = LocalizeOptions<T[]> & {
  getPageParam: (page: number) => string;
  checkNextPage?: (data: any, config?: LocalizePageConfig) => boolean;
  checkPrevPage?: (data: any, config?: LocalizePageConfig) => boolean;
  resolveConfig?: (data: any) => LocalizePageConfig;
  configSelector?: string;
};
export type LocalizePageConfig = {
  page: number;
  pageSize: number;
  rowCount: number;
  pageCount: number;
};

const _defaultPageCheckNext = (data: any, config?: LocalizePageConfig) => {
  if (config) {
    return config.page < config.pageCount;
  } else if (isArray(data)) return data.length !== 0;
  return true;
};

const _defaultPageCheckPrev = (data: any, config?: LocalizePageConfig) => {
  if (config) {
    return config.page > 1;
  } else if (isArray(data)) return data.length !== 0;
  return true;
};

// data in store
type LocPageStore<T> = {
  latest: T[];
  all: [T[]];
  config?: LocalizePageConfig;
};
export const useLocalizePage = <T = any>(storePath: string, options: LocalizePageOptions<T>): LocalizePageReturnType<T> => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1); // current page starts at 1
  const [hasNext, setHasNext] = useState(true);
  const [hasPrev, setHasPrev] = useState(false);

  const fetchNextPage = useCallback(() => {
    if (hasNext) {
      setPage((p) => p + 1);
    }
  }, [hasNext]);

  const fetchPrevPage = useCallback(() => {
    if (hasPrev) {
      setPage((p) => {
        const prev = p - 1 > 0 ? p - 1 : 1;
        return prev;
      });
    }
  }, [hasPrev]);

  const fetchPage = useCallback((page: number) => {
    setPage(page);
  }, []);

  const reset = useCallback(() => {
    setPage(1);
    localizeClear(storePath);
  }, [storePath]);

  const { data: latestPage } = useLocalize(`${storePath}.latest`);
  const { data: allPages } = useLocalize<[T[]]>(`${storePath}.all`);
  const { data: config } = useLocalize<LocalizePageConfig>(`${storePath}.config`);

  const currentPage = allPages?.[page];

  const param = options.getPageParam(page);
  const api = options.api.indexOf("?") > 0 ? `${options.api}&${param}` : `${options.api}${param === "" ? "" : `?${param}`}`;
  const pagePath = `${storePath}.all[${page}]`;
  const configSelector = options.configSelector || "page";

  // reset if needed
  useEffectMemo(() => {
    reset();
  }, [options.api, storePath]);

  const evaluateNextPrev = (data: any, config?: LocalizePageConfig) => {
    const canNext = (options.checkNextPage || _defaultPageCheckNext)(data, config);
    const canPrev = (options.checkPrevPage || _defaultPageCheckPrev)(data, config);
    setHasNext(canNext);
    setHasPrev(canPrev);
  };

  const resolvedData = (rawdata: any) => {
    const newConfig: LocalizePageConfig | undefined = options.resolveConfig?.(rawdata) || rawdata?.[configSelector];
    const data = rawdata?.data;

    // check next/prev available
    evaluateNextPrev(data, newConfig);

    const pageData: T[] = options.resolvedData?.(data) || data;
    const all: [T[]] = [...(allPages || [[]])];
    all[page] = pageData;

    const storeData: LocPageStore<T> = {
      latest: pageData,
      all,
      config: newConfig,
    };

    // update page store
    dispatch({
      type: LocalizeAction.SET_DATA,
      payload: {
        storePath,
        data: storeData,
      },
    });
    return pageData;
  };

  const { status } = useLocalize(pagePath, { ...options, api, resolvedData });

  return {
    latestPage,
    allPages,
    currentPage,
    data: remove(flatten(allPages), (v) => v !== undefined),
    config,
    fetchNextPage,
    fetchPrevPage,
    fetchPage,
    reset,
    status,
    hasNext,
    hasPrev,
  };
};

export const localizeRefresh = (storePath: string) => {
  const store = window._app.store;
  if (!store) return;

  const dispatch = store.dispatch;
  const key = cacheKey(storePath, true);
  if (__cache[key]) {
    // only refresh the request with api
    setTimeout(() => dispatch(localizeAction_setData(storePath, "", __cache[key].requestOptions) as any), 0);
  }
};

export const localizeClear = (storePath: string) => {
  const store = window._app.store;
  if (!store) return;

  const dispatch = store.dispatch;
  // also clear cache with key
  const key = keyStore(storePath);
  for (let k in __cache) {
    if (k.startsWith(key)) unset(__cache, k);
  }
  dispatch(localizeAction_Clear(storePath));
};

export const localizeSetData = (storePath: string, data: any) => {
  const store = window._app.store;
  if (!store) return;

  const dispatch = store.dispatch;
  setTimeout(() => {
    dispatch({
      type: LocalizeAction.SET_DATA,
      payload: {
        storePath,
        data,
      },
    });
  }, 0);
};

// utils functions

// use store path as key for request with api, other child path will get the status from parent
// get the cache for a request
const cacheKey = (storePath: string, absolute: boolean) => {
  const key = keyStore(storePath);
  if (absolute || __cache[key]) return key;
  // loop to find the parent
  for (let k in __cache) {
    if (key.startsWith(k)) return k;
  }
  return key;
};
// the the cache key for given store
const keyStore = (storePath: string) => {
  const language = window._app.getLanguage();
  return storePath.startsWith(".") ? storePath : `${language}#${storePath}`;
};

type CacheObject = {
  status: LocalizeReturnStatus;
  retry: number; // retry time
  maxRetry: number; // max retry time
  api: string; // api that data successful/failed fetch
  requestOptions: LocalizeOptionsType | LocalizePageOptions; // keep for refresh
  cancelToken: CancelTokenSource;
};
const __cache: Record<string, CacheObject> = {};

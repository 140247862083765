import { set, unset } from "lodash";
import { LocalizeActionType } from "../actions/localizeAction";

export enum LocalizeAction {
  SET_DATA = "LOCALIZE_SET_DATA", // set data for path on current language
  CHANGE_LANGUAGE = "LOCALIZE_CHANGE_LANGUAGE",
  CLEAR = "LOCALIZE_CLEAR", // clear data at path
}

// action state type define
export interface LocalizeActionState {
  language: string; // current language
  data: { [key: string]: any }; // data for all languages
  active: { [key: string]: any }; // current active data base on language, active = data[language]
}

// action initial state
const initialState: LocalizeActionState = {
  language: "",
  data: {},
  active: {},
};

const localizeReducer = (state: LocalizeActionState = initialState, action: LocalizeActionType): LocalizeActionState => {
  switch (action.type) {
    case LocalizeAction.SET_DATA: {
      const payload = action.payload;
      const absPath = payload.storePath.startsWith(".");
      const path = absPath ? payload.storePath.substr(1) : payload.storePath;
      const language = payload.lang || state.language;
      // data for view
      set(absPath ? state.data : state.data[language], path, payload.data);
      !absPath && set(state.active, path, payload.data);
      return { ...state };
    }

    case LocalizeAction.CHANGE_LANGUAGE: {
      if (state.language !== action.payload.language) {
        state.data[action.payload.language] = state.data[action.payload.language] || {}; // default lang data
        state.active = { ...state.data[action.payload.language] };
        return { ...state, language: action.payload.language };
      }
      return state;
    }

    case LocalizeAction.CLEAR: {
      const payload = action.payload;
      const absPath = payload.storePath.startsWith(".");
      const path = absPath ? payload.storePath.substr(1) : payload.storePath;
      absPath && unset(state.data, path);
      !absPath && unset(state.data[state.language], path);
      !absPath && unset(state.active, path);

      return { ...state };
    }

    default:
      return state;
  }
};

export default localizeReducer;

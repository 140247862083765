import { About_Us_Mock_Data } from "./mock-about-us";
import { Application_List_Mock_Data, Application_Mock_Data } from "./mock-application";
import { Blog_Mock_Data, Blogs_Mock_Data } from "./mock-blog";
import { ContactUs_Mock_Data } from "./mock-contact-us";
import { Common_Mock_Data } from "./mock-header";
import { Homepage_Mock_Data } from "./mock-homepage";
import { Product_Mock_Data } from "./mock-product";
import { Product_Detail_Mock_Data } from "./mock-product-detail";
import { Related_Prod_List_Mock, Related_Prod_Mock } from "./mock-related-prod";
import { Search_Mock_Data } from "./mock-search";
import { Service_Mock_Data } from "./mock-service";

export const ViewsData = {
  homepage: { data: { homepage: Homepage_Mock_Data } },
  product: { data: { productCategories: Product_Mock_Data } },
  productDetail: { data: { products: Product_Detail_Mock_Data } },
  common: { data: { common: Common_Mock_Data } },
  aboutUs: { data: { aboutUs: About_Us_Mock_Data } },
  productDetailRelated: { data: { productDetail: Related_Prod_Mock } },
  productRelatedList: { data: { products: Related_Prod_List_Mock } },
  search: { data: Search_Mock_Data },
  application: { data: { applications: Application_Mock_Data } },
  applicationList: { data: { applications: Application_List_Mock_Data } },
  service: { data: { service: Service_Mock_Data } },
  blogpage: { data: Blog_Mock_Data },
  blogDetail: { data: { blogDetail: Blogs_Mock_Data } },
  contact: { data: { contactUsPage: ContactUs_Mock_Data } },
};

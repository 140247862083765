import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";

import "./Modal.scss";

export interface ModalProps {
  rootElm?: string;
  isOpen: boolean;
  onBackClick?: () => void;
  onKeyEcs?: () => void;
  portal?: boolean;
  classes?: {
    root?: string;
    mask?: string;
    content?: string;
  };
}

export const Modal: React.FC<ModalProps & React.HTMLAttributes<HTMLDivElement>> = (props) => {
  const { rootElm: root = "root", classes, isOpen, onBackClick, onKeyEcs, portal = true, ...others } = props;

  useEffect(() => {
    const handleKey = (e: KeyboardEvent) => {
      if (e.keyCode === 27 || e.key === "Escape") onKeyEcs?.();
    };
    const init = () => {
      applyBodyModal();
      document.addEventListener("keydown", handleKey);
    };
    const cleanup = () => {
      restoreBodyModal();
      document.removeEventListener("keydown", handleKey);
    };

    isOpen ? init() : cleanup();
    return () => {
      cleanup();
    };
    // eslint-disable-next-line
  }, [isOpen]);

  const modalContent = (
    <div className={classNames("modal", classes?.root)} {...others}>
      <div className={classNames("modal__mask", classes?.mask)} onClick={() => onBackClick?.()}></div>
      <div className={classNames("modal__content", classes?.content)}>{props.children}</div>
    </div>
  );

  return !isOpen ? null : portal ? createPortal(modalContent, (document.getElementById(root) || document.body) as HTMLElement) : modalContent;
};

type ModalWrapperType = Omit<ModalProps, "isOpen">;

export const useModal = (): [React.FC<ModalWrapperType>, (open: boolean) => void] => {
  const [isOpen, openModal] = useState(false);

  const setOpen = useCallback(
    (open: boolean) => {
      openModal(open);
    },
    [openModal]
  );

  const ModalWrapper: React.FC<ModalWrapperType> = (props) => (
    <Modal isOpen={isOpen} rootElm={props.rootElm} onBackClick={props.onBackClick}>
      {props.children}
    </Modal>
  );

  return [ModalWrapper, setOpen];
};

export interface ModalBackButtonProps {
  className?: string;
  onClick?: () => void;
}

export const ModalBackButton: React.FC<ModalBackButtonProps> = (props) => {
  return (
    <div onClick={() => props.onClick?.()} className={classNames("modal__back-button", props.className)}>
      {props.children}
    </div>
  );
};

const applyBodyModal = () => {
  const container = document.body as HTMLElement;
  if (isOverFlowing(container)) {
    const size = getScrollbarSize();
    container.style.paddingRight = `${size}px`;
  }
  container.style.overflow = "hidden";
  container.classList.add("modal-open");
};

const restoreBodyModal = () => {
  const container = document.body as HTMLElement;
  container.style.paddingRight = "";
  container.style.overflow = "";
  container.classList.remove("modal-open");
};

const getScrollbarSize = () => {
  const { body } = document;
  const scrollDiv = document.createElement("div");
  // Append element with defined styling
  scrollDiv.setAttribute("style", "width: 1337px; height: 1337px; position: absolute; left: -9999px; overflow: scroll;");
  body.appendChild(scrollDiv);
  // Collect width & height of scrollbar
  const scrollbarWidth = scrollDiv["offsetWidth"] - scrollDiv["clientWidth"];
  // Remove element
  body.removeChild(scrollDiv);

  return scrollbarWidth;
};

const isOverFlowing = (el: HTMLElement) => {
  var curOverf = el.style.overflow;

  if (!curOverf || curOverf === "visible") el.style.overflow = "hidden";
  var overflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
  el.style.overflow = curOverf;

  return overflowing;
};

import { LazyMedia, LazyMediaProps } from "libs/react-common";

// override props with width and height is required
interface NImageProps {
  alt: string;
  width: number;
  height: number;
  srcSet?: string;
}

const NImage: React.FC<React.DOMAttributes<HTMLElement> & LazyMediaProps & NImageProps> = (props) => {
  return <LazyMedia {...props} />;
};

export default NImage;

export const NImageBg: React.FC<React.DOMAttributes<HTMLElement> & LazyMediaProps> = (props) => {
  return (
    <div className="section-bg">
      <LazyMedia {...props} />
    </div>
  );
};

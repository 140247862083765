import { ProductCateDataType } from "pages/product/Product";

import ProductE910 from "assets/images/product/E910.png";
import ProductBanner from "assets/images/product/product-banner.jpg";

export const Product_Mock_Data: ProductCateDataType[] = [
  {
    title: "Geometric measurement",
    description:
      "Geometric measurement technology spans an extremely wide field. From relatively simple straightness measurements of a member to considerably more complicated right angle measurements of machine tools and checks of turbines for power generation.",
    desktopBg: { url: ProductBanner },
    products: [
      {
        name: "E910 - Flange",
        shortDescription:
          "Easy-Laser® E910 Flange is a system used to measure flange flatness of windmill towers, wings and hubs, tower bases, etc. It can also be used for parallelism measurement.",
        slug: "e910-flange",
        image: { url: ProductE910 },
      },
      {
        name: "E910 - Flange",
        shortDescription:
          "Easy-Laser® E910 Flange is a system used to measure flange flatness of windmill towers, wings and hubs, tower bases, etc. It can also be used for parallelism measurement.",
        slug: "e910-flange",
        image: { url: ProductE910 },
      },
      {
        name: "E910 - Flange",
        shortDescription:
          "Easy-Laser® E910 Flange is a system used to measure flange flatness of windmill towers, wings and hubs, tower bases, etc. It can also be used for parallelism measurement.",
        slug: "e910-flange",
        image: { url: ProductE910 },
      },
      {
        name: "E910 - Flange",
        shortDescription:
          "Easy-Laser® E910 Flange is a system used to measure flange flatness of windmill towers, wings and hubs, tower bases, etc. It can also be used for parallelism measurement.",
        slug: "e910-flange",
        image: { url: ProductE910 },
      },
      {
        name: "E910 - Flange",
        shortDescription:
          "Easy-Laser® E910 Flange is a system used to measure flange flatness of windmill towers, wings and hubs, tower bases, etc. It can also be used for parallelism measurement.",
        slug: "e910-flange",
        image: { url: ProductE910 },
      },
      {
        name: "E910 - Flange",
        shortDescription:
          "Easy-Laser® E910 Flange is a system used to measure flange flatness of windmill towers, wings and hubs, tower bases, etc. It can also be used for parallelism measurement.",
        slug: "e910-flange",
        image: { url: ProductE910 },
      },
      {
        name: "E910 - Flange",
        shortDescription:
          "Easy-Laser® E910 Flange is a system used to measure flange flatness of windmill towers, wings and hubs, tower bases, etc. It can also be used for parallelism measurement.",
        slug: "e910-flange",
        image: { url: ProductE910 },
      },
      {
        name: "E910 - Flange",
        shortDescription:
          "Easy-Laser® E910 Flange is a system used to measure flange flatness of windmill towers, wings and hubs, tower bases, etc. It can also be used for parallelism measurement.",
        slug: "e910-flange",
        image: { url: ProductE910 },
      },
      {
        name: "E910 - Flange",
        shortDescription:
          "Easy-Laser® E910 Flange is a system used to measure flange flatness of windmill towers, wings and hubs, tower bases, etc. It can also be used for parallelism measurement.",
        slug: "e910-flange",
        image: { url: ProductE910 },
      },
      {
        name: "E910 - Flange",
        shortDescription:
          "Easy-Laser® E910 Flange is a system used to measure flange flatness of windmill towers, wings and hubs, tower bases, etc. It can also be used for parallelism measurement.",
        slug: "e910-flange",
        image: { url: ProductE910 },
      },
      {
        name: "E910 - Flange",
        shortDescription:
          "Easy-Laser® E910 Flange is a system used to measure flange flatness of windmill towers, wings and hubs, tower bases, etc. It can also be used for parallelism measurement.",
        slug: "e910-flange",
        image: { url: ProductE910 },
      },
    ],
  },
];

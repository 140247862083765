import classNames from "classnames";
import { merge } from "lodash";
import React, { useEffect, useRef, useState } from "react";

import { useIntersection } from "libs/react-hooks";

import { getMimeType } from "../functions";
import { LazyType } from "./LazyLoad";

import "./LazyMedia.scss";

interface LazyStyle {
  loading?: React.CSSProperties;
  default?: React.CSSProperties;
}

export interface LazyMediaProps {
  className?: string;
  preview?: JSX.Element | null;
  alt: string;
  src: string | undefined;
  width?: number;
  height?: number;
  load?: LazyType;
  lazyStyle?: LazyStyle | null;
  thumbnail?: boolean | string;
  boundary?: number;
  imageProps?: React.ImgHTMLAttributes<HTMLImageElement> | null;
  videoProps?: React.VideoHTMLAttributes<HTMLVideoElement> | null;
  onLoaded?: () => void;
}

type PropType = React.PropsWithChildren<LazyMediaProps & React.HTMLAttributes<HTMLImageElement | HTMLVideoElement>>;

export interface LazyMediaDefaultType extends Pick<LazyMediaProps, "preview" | "load" | "lazyStyle" | "thumbnail" | "boundary" | "imageProps" | "videoProps"> {
  thumbnailApi: string;
}

let _defaultOptions: Partial<LazyMediaDefaultType> = {
  thumbnailApi: "api/thumbnail?w=24&h=AUTO&imageUrl=",
  preview: undefined,
  load: "inview",
  lazyStyle: {
    default: { transition: "filter 0.5s linear" },
    loading: { filter: "blur(30px)" },
  },
  thumbnail: true,
  boundary: 100,
  imageProps: undefined,
  videoProps: { autoPlay: true, loop: true, muted: true, playsInline: true },
};

export const LazyMedia_setDefault = (options: Partial<LazyMediaDefaultType>) => {
  _defaultOptions = merge(_defaultOptions, options);
};

const placeholderImg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";

export const LazyMedia = React.forwardRef<any, PropType>((props, ref) => {
  let {
    className,
    src,
    width,
    height,
    alt,
    preview = _defaultOptions.preview,
    load = _defaultOptions.load,
    boundary = _defaultOptions.boundary,
    lazyStyle = _defaultOptions.lazyStyle,
    thumbnail = _defaultOptions.thumbnail,
    videoProps = _defaultOptions.videoProps,
    imageProps = _defaultOptions.imageProps,
    onLoaded,
    style,
    ...others
  } = props;

  const [viewRef, setViewRef] = useState<HTMLImageElement | HTMLVideoElement | null>(null);
  const { inview } = useIntersection(load === "inview" ? viewRef : null, { once: true, rootMargin: `${boundary}px` });

  const mimeType = getMimeType(src?.split(".").pop());

  let executing = true; // always
  if (load === "inview") executing = inview;
  else if (typeof load === "boolean") executing = load;

  const { state, lazysrc } = useLazyMedia(src, executing, thumbnail);
  const loading = state !== "loaded";

  useEffect(() => {
    if (!loading) onLoaded?.();
  }, [loading, onLoaded]);

  const lazyStyles: React.CSSProperties =
    !src || (!thumbnail && !preview)
      ? {}
      : loading
      ? {
          ...(mimeType === "image" ? { ...lazyStyle?.default, ...lazyStyle?.loading } : undefined),
          aspectRatio: width && height ? `${width}/${height}` : undefined,
          // paddingBottom: width && height ? `${(height / width) * 100}%` : undefined,
          // height: width && height ? "0" : undefined,
        }
      : { ...(mimeType === "image" ? { ...lazyStyle?.default } : undefined) };

  const placeholerSize = loading ? { width } : undefined; // only need set width, height auto will be set by aspectRatio

  return !src ? null : loading && props.preview ? (
    React.cloneElement(props.preview, {
      className: className,
      ref: viewRef,
    })
  ) : mimeType === "video" && lazysrc ? (
    <video
      ref={(video) => {
        setViewRef(video);
        ref && (typeof ref === "function" ? ref(video) : (ref.current = video));
      }}
      className={classNames("lazy-media", className, `lazy-media--${loading ? "loading" : "loaded"}`)}
      src={lazysrc}
      style={{ ...lazyStyles, ...style }}
      {...placeholerSize}
      {...others}
      {...videoProps}
    />
  ) : (
    <img
      ref={(img) => {
        setViewRef(img);
        ref && (typeof ref === "function" ? ref(img) : (ref.current = img));
      }}
      className={classNames("lazy-media", className, `lazy-media--${loading ? "loading" : "loaded"}`)}
      src={lazysrc || placeholderImg}
      alt={alt}
      style={{ ...lazyStyles, ...style }}
      {...placeholerSize}
      {...others}
      {...imageProps}
    />
  );
});

export default LazyMedia;

type LazyState = "idle" | "loading" | "loaded";

const useLazyMedia = (source: string | undefined, enable: boolean = true, thumbnail: boolean | string = true) => {
  const [state, setState] = useState<LazyState>("idle");
  const cache = useRef<string[]>([]); // cache loaded source
  const mimeType = getMimeType(source?.split(".").pop());

  // reset state when source change
  useEffect(() => {
    if (source) {
      cache.current.includes(source) ? setState("loaded") : enable ? setState("loading") : setState("idle");
    } else {
      setState("idle");
    }
  }, [source, enable]);

  const canRun = enable && state === "loading";
  useEffect(() => {
    if (canRun && source) {
      if (mimeType === "image") {
        const img = new Image();
        img.onload = () => {
          cache.current.push(source);
          setState("loaded");
        };
        img.src = source;

        return () => {
          img.onload = null;
        };
      } else {
        setState("loaded"); // for video just update state, dont preload
      }
    }
  }, [source, canRun, mimeType]);

  const thumbnailApi = typeof thumbnail === "string" ? thumbnail : thumbnail ? _defaultOptions.thumbnailApi : "";

  let lazysrc = source;
  if (state === "idle") lazysrc = "";
  // invalid source
  else if (state === "loading") {
    // check for use thumb
    if (mimeType === "image" && thumbnailApi) {
      lazysrc = `${thumbnailApi}${source}`;
    } else {
      lazysrc = "";
    }
  } else if (state === "loaded") {
    lazysrc = source;
  }

  return { lazysrc, state };
};

export { useLazyMedia };

export const commonQuery = (langCode: string = "vi") => `
query {
  common (locale: "${langCode}"){
    header
    commonText
    footer {
      leftContent
      rightContent
    }
  }
}
`;

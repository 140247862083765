export type LoadScriptCallback = (script?: string) => void;

export interface ScriptOptions {
  async: boolean;
  defer: boolean;
}
const loadedScripts: string[] = [];

export const loadScript = (scriptUrl: string, success?: LoadScriptCallback, failed?: LoadScriptCallback, options?: ScriptOptions) => {
  if (loadedScripts.indexOf(scriptUrl) >= 0) {
    success?.(scriptUrl);
    return;
  }

  if (typeof window === "undefined" || !window.document) return;

  const { async = true, defer = true } = options || {};

  let script = document.createElement("script");
  script.src = scriptUrl;
  // load options
  script.async = async;
  script.defer = defer;

  const onSuccess = () => {
    loadedScripts.push(scriptUrl);
    success?.(scriptUrl);
    removeListener();
  };

  const onError = () => {
    failed?.(scriptUrl);
    removeListener();
  };

  const removeListener = () => {
    script.removeEventListener("load", onSuccess);
    script.removeEventListener("error", onError);
  };

  script.addEventListener("load", onSuccess);
  script.addEventListener("error", onError);
  document.body.appendChild(script);
};

export const loadScriptParallel = (scripts: string[], success?: LoadScriptCallback, failed?: LoadScriptCallback, options?: ScriptOptions) => {
  let nLoaded = 0;
  const onSuccess = (script?: string) => {
    nLoaded++;
    if (nLoaded === scripts.length) {
      // all scripts loaded
      success?.();
    }
  };

  const onError = (script?: string) => {
    failed?.(script);
  };

  scripts.forEach((script) => {
    loadScript(script, onSuccess, onError, options);
  });
};

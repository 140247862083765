import debounce from "lodash/debounce";
import pick from "lodash/pick";
import { useEffect } from "react";
import { useSelector, useStore } from "react-redux";
import { ActionCreator } from "redux";

import { ActionTypeCommon, StoreTypeCommon } from "../index";
import { SystemAction } from "../reducers/systemReducer";

// action type define
export type SystemActionType = SystemAction_SetScreenSize | SystemAction_SetResponsive; // | any other action;

// Screen size
export interface SystemAction_SetScreenSize extends ActionTypeCommon {
  type: SystemAction.SET_SCREEN_SIZE;
  payload: {
    width: number;
    height: number;
  };
}

export const systemAction_SetScreenSize: ActionCreator<SystemActionType> = (width: number, height: number) => {
  return {
    type: SystemAction.SET_SCREEN_SIZE,
    payload: { width, height },
  };
};

export const useScreensize = () => {
  const screensize = useSelector<StoreTypeCommon, { width: number; height: number }>((store) => store.system.screenSize);
  return screensize;
};
export const useScreenWidth = () => {
  const width = useSelector<StoreTypeCommon, number>((store) => store.system.screenSize.width);
  return width;
};

// Responsive
const defaultMediaQueries = {
  mobile: "(max-width: 619px)",
  tablet: "(min-width: 620px)",
  laptop: "(min-width: 1024px) and (max-height: 780px)",
  desktop: "(min-width: 1024px)",
  "desktop-2k": "(min-width: 2348px)",
  "desktop-4k": "(min-width: 3500px)",
};

type DefaultResponsiveMediaType = Record<keyof typeof defaultMediaQueries, boolean>;
export type ResponsiveMediaType = DefaultResponsiveMediaType | Record<string, boolean>;
export interface SystemAction_SetResponsive extends ActionTypeCommon {
  type: SystemAction.SET_RESPONSIVE_SIZE;
  payload: ResponsiveMediaType;
}

let mediaQueries: Record<string, string> | undefined = undefined;
export const setSystemMediaQueries = (queries: Record<string, string>) => {
  mediaQueries = queries;
};

export const systemAction_SetResponsive: ActionCreator<SystemActionType> = (responsive: ResponsiveMediaType) => {
  return {
    type: SystemAction.SET_RESPONSIVE_SIZE,
    payload: responsive,
  };
};

export function useResponsive<T extends ResponsiveMediaType, R extends keyof T = keyof T>(media: R): boolean;
export function useResponsive<T extends ResponsiveMediaType, R extends keyof T = keyof T>(media?: Array<R>): Pick<T, R>;
export function useResponsive<T extends ResponsiveMediaType, R extends keyof T = keyof T>(media: R | Array<R>): boolean | Pick<T, R> {
  const responsive = useSelector<StoreTypeCommon, any>((store) => {
    if (media === undefined) return store.system.responsive;
    if (typeof media === "string") return (store.system.responsive as any)[media];
    return pick(store.system.responsive, media);
  });

  return responsive;
}

// register system itself
export const useSystem = () => {
  const dispatch = useStore().dispatch;
  // register screen size change
  useEffect(() => {
    dispatch(systemAction_SetScreenSize(window.innerWidth, window.innerHeight)); // first time set
    const handleResize = debounce(() => {
      dispatch(systemAction_SetScreenSize(window.innerWidth, window.innerHeight));
    }, 250);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // init/register for media query
  useEffect(() => {
    const queries = mediaQueries || defaultMediaQueries;
    const mediaQueryList: Record<string, MediaQueryList> = {};
    const handleQueryChange = () => {
      const matches: Record<string, boolean> = {};
      for (const media in queries) {
        matches[media] = mediaQueryList[media] && mediaQueryList[media].matches;
      }
      dispatch(systemAction_SetResponsive(matches));
    };

    if (window && window.matchMedia) {
      const matches: Record<string, boolean> = {};
      for (const media in queries) {
        mediaQueryList[media] = window.matchMedia((queries as any)[media]);
        matches[media] = mediaQueryList[media].matches;
        mediaQueryList[media]["addEventListener"] ? mediaQueryList[media].addEventListener("change", handleQueryChange) : mediaQueryList[media].addListener(handleQueryChange);
      }
      dispatch(systemAction_SetResponsive(matches)); // first time set
    }

    return () => {
      for (const media in queries) {
        mediaQueryList[media]?.["removeEventListener"]
          ? mediaQueryList[media]?.removeEventListener("change", handleQueryChange)
          : mediaQueryList[media]?.removeListener(handleQueryChange);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

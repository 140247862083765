import { ProductDetailDataType } from "pages/product-detail/ProductDetail";

import ProductE910 from "assets/images/product/E910.png";

export const Product_Detail_Mock_Data: ProductDetailDataType[] = [
  {
    slug: "product-slug",
    name: "",
    product_category: {
      slug: "product-category",
      title: "product-title",
    },
    mainInfo: {
      name: "<p>Easy-Laser® <strong>E910</strong> - Flange</p>",
      title: `<h1><strong>Measure</strong>&nbsp;<strong>windmill tower flanges</strong><i> of any size</i></h1>`,
      image: [{ url: ProductE910 }, { url: ProductE910 }, { url: ProductE910 }, { url: ProductE910 }, { url: ProductE910 }],
      description:
        "Easy-Laser XT980 makes it fast and easy to measure and adjust reducers, counterholds, band and circular saw blades. The laser transmitter in XT980 can be used as a rotated point, which gives a ”plane” to measure against. It replaces the traditional cord/wire, that was tensioned in the centre, and enables you to move around freely in the machine, screwing and adjusting, while following the result of the adjustment in real time.",
    },
    otherInfo: [
      {
        infoName: "Info",
        content: `
     <p style="margin-left:0px;"><strong>See the result as a true 3D image in the display unit directly after measuring. Then evaluate the result easily with different calculation settings, for example three point reference, best fit or all positive. This can also be done directly on site without having to stop to go to a PC with separate analysis programs. This makes production much more efficient.</strong></p><p style="margin-left:0px;">The system includes laser transmitter D22 with manual rotatable head, and with the option of deflecting the laser beam 90°. With a few more accessories the system can also be used to check the parallelism of the two tower flanges. In the measurement system’s display unit it is possible to generate a PDF report containing graphs and measurement data. All information about the measurement object is documented.</p><figure class="image"><img src="${ProductE910}" data-width="504" data-height="284"></figure><h2>For all types of flanges</h2><p style="margin-left:0px;">Easy-Laser® Flange systems can be used on all sizes of flanges, regardless of diameter. You can measure 1 to 5 circles of measurement points, for example inner, middle and outer circles, in order to see the taper of the flange. Each circle can have 6–180 measurement points. The program guides you graphically step-by-step through the entire measurement, which quickens the measurement process. Three methods are available: Full circle measurement, Sectional measurement and Partial measurement.</p><figure class="media"><oembed url="https://youtu.be/CKJ80Bpm5fM"></oembed></figure><h2>Parallelism of tower flanges</h2><p style="margin-left:0px;">Easy-Laser® enables you to measure and check the parallelism of the flanges. This can be done in several ways, as shown to the right with system E910 for example. In addition to the standard equipment, two tripods and an angular prism are required.</p>`,
      },
      {
        infoName: "Features",
        content: `
     <p style="margin-left:0px;"><strong>See the result as a true 3D image in the display unit directly after measuring. Then evaluate the result easily with different calculation settings, for example three point reference, best fit or all positive. This can also be done directly on site without having to stop to go to a PC with separate analysis programs. This makes production much more efficient.</strong></p><figure class="image"><img src="${ProductE910}" data-width="504" data-height="284"></figure><h2>For all types of flanges</h2></p>`,
      },
      {
        infoName: "System Part",
        content: `
     <p style="margin-left:0px;"><strong>Sed porttitor lectus nibh. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. Proin eget tortor risus. Nulla quis lorem ut libero malesuada feugiat. Nulla quis lorem ut libero malesuada feugiat. Donec rutrum congue leo eget malesuada..</strong></p><figure class="image"><img src="${ProductE910}" data-width="504" data-height="284"></figure><h2>For all types of flanges</h2></p>`,
      },
      {
        infoName: "Technical Data",
        content: `
     <p style="margin-left:0px;"><strong>Vivamus suscipit tortor eget felis porttitor volutpat. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Donec sollicitudin molestie malesuada. Sed porttitor lectus nibh. Donec sollicitudin molestie malesuada. Cras ultricies ligula sed magna dictum porta. Donec sollicitudin molestie malesuada. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Nulla porttitor accumsan tincidunt.</strong></p><figure class="image"><img src="${ProductE910}" data-width="504" data-height="284"></figure><h2>For all types of flanges</h2></p>`,
      },
    ],
  },
];

import { ApplicationDetailDataType } from "pages/application-detail/ApplicationDetail";
import { ApplicationDataType } from "pages/application/Application";

import Energy from "assets/images/application/application-energy-production-thumbnail.jpg";
import OilnGas from "assets/images/application/application-oil-n-gas-thumbnail.jpg";
import ShipIndustry from "assets/images/application/application-ship_industry-thumbnail.jpg";
import WindPower from "assets/images/application/application-wind_power_industry-thumbnail.jpg";
import DesktopBg from "assets/images/application/banner-desktop.jpg";

export const Application_Mock_Data: ApplicationDataType = {
  banner: { title: "Applications for laser alignment", description: "", desktopBg: { url: DesktopBg } },
};

export const Application_List_Mock_Data: ApplicationDetailDataType[] = [
  {
    title: "Marine applications",
    subtitle: "",
    shortDescription:
      "Curabitur aliquet quam id dui posuere blandit. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Nulla quis lorem ut libero malesuada feugiat. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. Vivamus suscipit tortor eget felis porttitor volutpat. Cras ultricies ligula sed magna dictum porta. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.",
    btnText: "Learn more",
    slug: "marine-applications",
    thumbnail: { url: ShipIndustry },
    content: "",
    published_at: "",
    position: 1,
  },
  {
    title: "Energy production",
    subtitle: "",
    shortDescription:
      "Curabitur aliquet quam id dui posuere blandit. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Nulla quis lorem ut libero malesuada feugiat. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. Vivamus suscipit tortor eget felis porttitor volutpat. Cras ultricies ligula sed magna dictum porta. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.",
    btnText: "Learn more",
    slug: "energy-production",
    thumbnail: { url: Energy },
    content: "",
    published_at: "",
    position: 2,
  },
  {
    title: "Wind power",
    subtitle: "",
    shortDescription:
      "Curabitur aliquet quam id dui posuere blandit. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Nulla quis lorem ut libero malesuada feugiat. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. Vivamus suscipit tortor eget felis porttitor volutpat. Cras ultricies ligula sed magna dictum porta. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.",
    btnText: "Learn more",
    slug: "wind-power",
    thumbnail: { url: WindPower },
    content: "",
    published_at: "",
    position: 3,
  },
  {
    title: "Oil & gas",
    subtitle: "",
    shortDescription:
      "Curabitur aliquet quam id dui posuere blandit. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Nulla quis lorem ut libero malesuada feugiat. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. Vivamus suscipit tortor eget felis porttitor volutpat. Cras ultricies ligula sed magna dictum porta. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.",
    btnText: "Learn more",
    slug: "oil-n-gas",
    thumbnail: { url: OilnGas },
    content: "",
    published_at: "",
    position: 4,
  },
];

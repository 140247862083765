import { getCookie, setCookie } from "libs/functions";
import { LazyMedia_setDefault } from "libs/react-common";
import { setLocalizeMockData, setSystemMediaQueries } from "libs/redux-common";
import { LANGUAGE_KEY } from "utils/constants";

import api from "./api";
import { ViewsData } from "./mock-data/mock-all";
import polyfills from "./Polyfills";
import store from "./store";

// app init point, it run before any react comp
export const systemMediaQueries = {
  mobile: "(max-width: 619px)",
  tablet: "(min-width: 620px)",
  "tablet-xl": "(min-width: 1000px)",
  laptop: "(min-width: 1200px) and (max-height: 780px)",
  desktop: "(min-width: 1200px)",
  "desktop-2k": "(min-width: 2348px)",
  "desktop-4k": "(min-width: 3500px)",
};

export const appInit = () => {
  // polyfill lib
  polyfills();

  // appl implement instance
  appSetup();

  // repsonsive queries
  setSystemMediaQueries(systemMediaQueries);

  // lazy thumb image, default: api/thumbnail?w=24&h=AUTO&imageUrl=
  LazyMedia_setDefault({
    thumbnailApi: "", // set '' to disable auto thumb call
    boundary: 200,
  });

  // store mock data
  setLocalizeMockData(ViewsData);
};

const appSetup = () => {
  if (typeof window === "undefined") return;

  // main app export
  window._app = {};
  const app = window._app;

  // store
  app.store = store;

  // api lib
  app.api = api;

  // language until
  app.getLanguage = () => {
    return window.sessionStorage?.getItem(LANGUAGE_KEY) || getCookie(LANGUAGE_KEY) || "vi";
  };

  app.setLanguage = (lang: string) => {
    window.sessionStorage?.setItem(LANGUAGE_KEY, lang);
    setCookie(LANGUAGE_KEY, lang, 365);
  };
};

// Banner
import BannerDesktopBg from "assets/images/homepage/banner/desktop-bg.jpg";
// Blog
import BlogThumbnail1 from "assets/images/homepage/blog/thumbnail-blog-1.jpg";
import BlogThumbnail2 from "assets/images/homepage/blog/thumbnail-blog-2.jpg";
import BlogThumbnail3 from "assets/images/homepage/blog/thumbnail-blog-3.jpg";
import BlogThumbnail4 from "assets/images/homepage/blog/thumbnail-blog-4.jpg";
import BlogThumbnail5 from "assets/images/homepage/blog/thumbnail-blog-5.jpg";
import BlogThumbnail6 from "assets/images/homepage/blog/thumbnail-blog-6.jpg";
import BlogThumbnail7 from "assets/images/homepage/blog/thumbnail-blog-7.jpg";
// Product
// Sister Company
import AustalLogo from "assets/images/homepage/sister-company/Austal.png";
import SGCLogo from "assets/images/homepage/sister-company/SGC.png";
import XTLogo from "assets/images/homepage/sister-company/XT-logo.png";
// Why choose us
import WhyChooseUsImage from "assets/images/homepage/why-choose-us/why-choose-us.jpg";

import { HomepageDataType } from "../pages/homepage/Homepage";

export const Homepage_Mock_Data: HomepageDataType = {
  metaTags: "<title>Homepage | VLA</title>",
  banner: {
    title: "PORDER IN SMALL BALANCED EQUIPMENT",
    description:
      "With more than 20 years in the field of laser equipment maintenance and repair, VLA is committed to providing customers with the best services. Besides, we support and provide calibration and vibration services 24/7.",
    desktopBg: { url: BannerDesktopBg },
  },
  product: {
    title: "PRODUCTS",
    shortDescription:
      "Sed porttitor lectus nibh. Donec rutrum congue leo eget malesuada. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Vivamus suscipit tortor eget felis porttitor volutpat.",
    exploreBtnText: "EXPLORE",
    exploreUrl: "/product",
    // productList: [
    //   {
    //     name: "Easy-Laser® E290 Vivamus suscipit tortor eget",
    //     image: { url: ProductE290 },
    //     slug: "easy-laser",
    //     description: "",
    //   },
    //   {
    //     name: "Easy-Laser® E930",
    //     image: { url: ProductE930 },
    //     slug: "easy-laser",
    //     description: "",
    //   },
    //   {
    //     name: "Easy-Laser® XT190",
    //     image: { url: ProductXT190 },
    //     slug: "easy-laser",
    //     description: "",
    //   },
    //   {
    //     name: "Easy-Laser® XT550",
    //     image: { url: ProductXT550 },
    //     slug: "easy-laser",
    //     description: "",
    //   },
    //   {
    //     name: "Easy-Laser® XT660",
    //     image: { url: ProductXT660 },
    //     slug: "easy-laser",
    //     description: "",
    //   },
    //   {
    //     name: "Easy-Laser® XT770",
    //     image: { url: ProductXT770 },
    //     slug: "easy-laser",
    //     description: "",
    //   },
    // ],
  },
  whyChooseUs: {
    title: "WHY YOU SHOULD CHOOSE VIETNAM LASER ALIGNMENT",
    image: { url: WhyChooseUsImage },
    description: `<ul>
      <li>Over 20 years of experience in the laser instrument calibration industry</li>
      <li>As one of the pioneers in the laser alignment industry in Vietnam</li>
      <li>Provide equipment, service and training to customers</li>
      <li>Support 24/7</li>
      </ul>`,
    aboutUsText: "ABOUT US",
    aboutUsUrl: "/ve-chung-toi",
  },
  blog: {
    title: "BLOG",
    description: "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae",
    btnLink: "/blog",
    btnText: "SEE MORE",
    blogList: [
      {
        title: "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.",
        description: "",
        slug: "blog-1",
        thumbnail: { url: BlogThumbnail1 },
      },
      {
        title: "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.",
        description: "",
        slug: "blog-1",
        thumbnail: { url: BlogThumbnail2 },
      },
      {
        title: "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.",
        description: "",
        slug: "blog-1",
        thumbnail: { url: BlogThumbnail3 },
      },
      {
        title: "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.",
        description: "",
        slug: "blog-1",
        thumbnail: { url: BlogThumbnail4 },
      },
      {
        title: "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.",
        description: "",
        slug: "blog-1",
        thumbnail: { url: BlogThumbnail5 },
      },
      {
        title: "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.",
        description: "",
        slug: "blog-1",
        thumbnail: { url: BlogThumbnail6 },
      },
      {
        title: "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.",
        description: "",
        slug: "blog-1",
        thumbnail: { url: BlogThumbnail7 },
      },
    ],
  },
  referrenceCompany: {
    title: "REFERENCE COMPANY",
    logos: [
      {
        logo: { url: AustalLogo },
      },
      {
        logo: { url: SGCLogo },
        link: "/",
      },
      {
        logo: { url: XTLogo },
      },
      {
        logo: { url: AustalLogo },
      },
      {
        logo: { url: SGCLogo },
        link: "/",
      },
      {
        logo: { url: XTLogo },
      },
      {
        logo: { url: AustalLogo },
      },
      {
        logo: { url: SGCLogo },
        link: "/",
      },
      {
        logo: { url: XTLogo },
      },
    ],
  },
};

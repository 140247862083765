import classNames from "classnames";
import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

import { SideLinkDataType } from "components/header/side-menu/SideMenu";
import { isAbsoluteUrl } from "libs/react-common";
import { useLocalize } from "libs/redux-common";

import { ReactComponent as Arrow } from "assets/images/icons/arrow.svg";

import styles from "./TopNav.module.scss";

export interface TopNavProps {}

const TopNav: React.FC<TopNavProps> = (props) => {
  const { data } = useLocalize<SideLinkDataType[]>("common.header");

  return (
    <div className={styles["root"]}>
      {data?.map((item, index) =>
        item.child ? <TopLinkGroupL1 {...item} key={`${item.label}-${index}`} /> : item.label && item.url ? <TopLinkItem {...item} key={`${item.url}-${index}`} /> : null
      )}
    </div>
  );
};

export default TopNav;

interface TopLinkGroupL1Props extends SideLinkDataType {}

const TopLinkGroupL1: React.FC<TopLinkGroupL1Props> = (props) => {
  const { label, child, url } = props;
  const location = useLocation().pathname.split("/")[1];
  const isActivated = url ? url.split("/")[1] === location : child && child[0].url.split("/")[1] === location;

  return (
    <div className={classNames(styles["top-link"], styles["link-groupl1"], { [styles["is-active"]]: isActivated })}>
      {url ? <Link to={url}>{label}</Link> : label}
      <Arrow className={classNames(styles["link-arrow"], styles["link-arrowl1"])} />
      <div className={styles["groupl1-content"]}>{child?.map((item) => (item.label && item.url ? <TopLinkItem {...item} key={item.url} /> : null))}</div>
    </div>
  );
};

interface TopLinkItemType {
  url: string;
  label: string;
}

const TopLinkItem: React.FC<TopLinkItemType> = (props) => {
  const { url, label } = props;
  const absolute = isAbsoluteUrl(url);

  return (
    <div className={styles["top-link"]}>
      {absolute ? (
        <a href={url}>{label}</a>
      ) : (
        <NavLink exact to={props.url} activeClassName={styles["top-link--selected"]}>
          {label}
        </NavLink>
      )}
    </div>
  );
};

import { useEffect, useState } from "react";

type UseIntersectionProps = {
  once?: boolean;
};
export type UseIntersectionOptions = IntersectionObserverInit & UseIntersectionProps;

export const useIntersection = (elm: HTMLElement | string | null, options?: UseIntersectionOptions) => {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();
  const { once, root, rootMargin, threshold } = options || {};

  useEffect(() => {
    const node = typeof elm == "string" ? document.querySelector(elm) : elm;

    if (node && window.IntersectionObserver) {
      const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        setEntry(entries[0]);
        once && entries[0].isIntersecting && observer.disconnect();
      };

      const observer = new window.IntersectionObserver(handleIntersection, {
        root: root,
        rootMargin: rootMargin,
        threshold: threshold,
      });
      observer.observe(node);

      return () => observer.disconnect();
    }
  }, [elm, once, root, rootMargin, threshold]);

  return { inview: entry?.isIntersecting ? true : false, entry };
};

export default useIntersection;

import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import PageNotFound from "components/page-not-found/PageNotFound";
import { useLanguage, useSystem } from "libs/redux-common";

import { appInit } from "./AppInit";

const HomePage = React.lazy(() => import("pages/homepage/Homepage"));
const Product = React.lazy(() => import("pages/product/Product"));
const ProductDetail = React.lazy(() => import("pages/product-detail/ProductDetail"));
const AboutUs = React.lazy(() => import("pages/about-us/AboutUs"));
const Application = React.lazy(() => import("pages/application/Application"));
const ApplicationDetail = React.lazy(() => import("pages/application-detail/ApplicationDetail"));
const SearchResult = React.lazy(() => import("pages/search-result/SearchResult"));
const Service = React.lazy(() => import("pages/service/Service"));
const Blogs = React.lazy(() => import("pages/blogs/Blogs"));
const BlogDetail = React.lazy(() => import("pages/blog-detail/BlogDetail"));
const ContactUs = React.lazy(() => import("pages/contact-us/ContactUs"));

appInit();

const App: React.FC = () => {
  useLanguage(); // load save/default language
  useSystem(); // init system

  return (
    <div className="App">
      <Header />
      <Suspense fallback={null}>
        <Switch>
          <Route path="/" exact>
            <HomePage />
          </Route>

          <Route path={["/about-us", "/ve-chung-toi"]} exact>
            <AboutUs />
          </Route>

          <Route path={["/product/:category", "/san-pham/:category"]} exact>
            <Product />
          </Route>

          <Route path={["/product/:category/:productName", "/san-pham/:category/:productName"]}>
            <ProductDetail />
          </Route>

          <Route path={["/application", "/ung-dung"]} exact>
            <Application />
          </Route>

          <Route path={["/application/:applicationName", "/ung-dung/:applicationName"]} exact>
            <ApplicationDetail />
          </Route>

          <Route path={["/search", "/tim-kiem"]} exact>
            <SearchResult />
          </Route>

          <Route path={["/services", "/dich-vu"]} exact>
            <Service />
          </Route>

          <Route path={["/contact", "/lien-he"]} exact>
            <ContactUs />
          </Route>

          <Route path="/blog" exact>
            <Blogs />
          </Route>

          <Route path="/blog/:blogName">
            <BlogDetail />
          </Route>

          <Route path="/404" exact>
            <PageNotFound />
          </Route>
          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </Suspense>
      <Footer />
    </div>
  );
};

export default App;

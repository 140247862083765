import React from "react";

import { useGetLanguage, useLocalize } from "libs/redux-common";
import { APP_API } from "utils/constants";

import { commonQuery } from "./common.query";
import SideMenu from "./side-menu/SideMenu";
import TopHeader from "./top-header/TopHeader";

import styles from "./Header.module.scss";

export interface HeaderDataType {}

export interface HeaderProps {}

const Header: React.FC<HeaderProps> = (props) => {
  const langCode = useGetLanguage();

  useLocalize<any>("common", {
    api: `${APP_API}/graphql`,
    method: "POST",
    postData: { query: commonQuery(langCode) },
    selector: "data.common",
    mockPath: "common",
  });

  return (
    <div className={styles["root"]}>
      <TopHeader />
      <SideMenu />
    </div>
  );
};

export default Header;

import { ServiceDataType } from "pages/service/Service";

import ScenePicture from "assets/images/service/scene-picture.jpg";
import DesktopBanner from "assets/images/service/Service-desktop-banner.jpg";
import ServiceExample from "assets/images/service/shaft-concentric-alignment.jpg";

export const Service_Mock_Data: ServiceDataType = {
  bannerDesktopBg: { url: DesktopBanner },
  bannerTitle: "SERVICE",
  // bannerDescription: `<p><strong>VLA</strong> is proud to be the equipment distributor that brings a quick, professional and quality repair service package.</p>
  // <p>As one of the pioneers in the field of laser calibration in Vietnam, we are ready to respond immediately when there is a request for troubleshooting or equipment not working, regardless of night or holidays.</p>
  // <p>Repair and maintenance service package monthly/quarterly/yearly.</p>
  // <p>Carry out periodic inspection and maintenance of equipment in accordance with the manufacturer's recommendations and regulations, and provide shim for maintenance and repair at the request of customers.</p>`,
  serviceListTitle: "TITLE",
  serviceListContent: `<p>
  <strong>
    Máy móc được cân chỉnh chính xác sẽ giảm thiểu nguy cơ hư hỏng thay thế đắt tiền và thời gian sửa chữa. Một máy thiếu cân chỉnh có thể bị những lỗi sau đây:
  </strong>
</p>
<ul>
  <li>Mất quá nhiều thời gian sản xuất</li>
  <li>Rò rỉ phốt làm kín</li>
  <li>Tăng độ rung động</li>
  <li>Nhiệt độ bạc đạn cao</li>
  <li>Hư bạc đạn</li>
  <li>Nứt / Gãy trục</li>
  <li>Hao mòn khớp nối</li>
  <li>Giảm chất lượng sản phẩm</li>
  <li>Môi trường làm việc không tốt</li>
  <li>Điện năng tiêu thụ tăng</li>
</ul>
<p><strong>VLA</strong> tự hào là đại diện phân phối thiết bị mang lại gói dịch vụ sửa nhanh chóng, chuyên nghiệp và chất lượng.</p>
<p>
  Là một trong những nhà tiên phong trong lĩnh vực cân chỉnh laser ở Việt nam, chúng tôi sẵn sang đáp ứng ngay khi có yêu cầu xử lý sự cố hoặc thiết bị không hoạt động,
  không kể ban đêm hay các ngày nghỉ lễ.
</p>`,
  serviceTitle: "VLA SERVICES",
  serviceList: [
    {
      title: "Takes too long to produce",
      image: { url: ServiceExample },
      description: "Cras ultricies ligula sed magna dictum porta. Donec sollicitudin molestie malesuada. Vivamus suscipit tortor eget felis porttitor volutpat. ",
    },
    {
      title: "Seal leak",
      image: { url: ServiceExample },
      description: "Cras ultricies ligula sed magna dictum porta. Donec sollicitudin molestie malesuada. Vivamus suscipit tortor eget felis porttitor volutpat. ",
    },
    {
      title: "Increase vibration",
      image: { url: ServiceExample },
      description: "Cras ultricies ligula sed magna dictum porta. Donec sollicitudin molestie malesuada. Vivamus suscipit tortor eget felis porttitor volutpat. ",
    },
    {
      title: "High bearing temperature",
      image: { url: ServiceExample },
      description: "Cras ultricies ligula sed magna dictum porta. Donec sollicitudin molestie malesuada. Vivamus suscipit tortor eget felis porttitor volutpat. ",
    },
    {
      title: "Damaged bearings",
      image: { url: ServiceExample },
      description: "Cras ultricies ligula sed magna dictum porta. Donec sollicitudin molestie malesuada. Vivamus suscipit tortor eget felis porttitor volutpat. ",
    },
    {
      title: "Crack / Shaft Break",
      image: { url: ServiceExample },
      description: "Cras ultricies ligula sed magna dictum porta. Donec sollicitudin molestie malesuada. Vivamus suscipit tortor eget felis porttitor volutpat. ",
    },
    {
      title: "Coupling wear",
      image: { url: ServiceExample },
      description: "Cras ultricies ligula sed magna dictum porta. Donec sollicitudin molestie malesuada. Vivamus suscipit tortor eget felis porttitor volutpat. ",
    },
    {
      title: "Decreasing product quality",
      image: { url: ServiceExample },
      description: "Cras ultricies ligula sed magna dictum porta. Donec sollicitudin molestie malesuada. Vivamus suscipit tortor eget felis porttitor volutpat. ",
    },
    {
      title: "Working environment is not good",
      image: { url: ServiceExample },
      description: "Cras ultricies ligula sed magna dictum porta. Donec sollicitudin molestie malesuada. Vivamus suscipit tortor eget felis porttitor volutpat. ",
    },
    {
      title: "Power consumption increased",
      image: { url: ServiceExample },
      description: "Cras ultricies ligula sed magna dictum porta. Donec sollicitudin molestie malesuada. Vivamus suscipit tortor eget felis porttitor volutpat. ",
    },
  ],
  sceneImageTitle: "SCENE PICTURES",
  sceneImageList: [
    {
      image: { url: ScenePicture },
      description: "Cras ultricies ligula sed magna dictum porta. Donec sollicitudin molestie malesuada. Vivamus suscipit tortor eget felis porttitor volutpat.",
    },
    {
      image: { url: ScenePicture },
      description: "Cras ultricies ligula sed magna dictum porta. Donec sollicitudin molestie malesuada. Vivamus suscipit tortor eget felis porttitor volutpat.",
      link: "/",
    },
    {
      image: { url: ScenePicture },
      description: "Cras ultricies ligula sed magna dictum porta. Donec sollicitudin molestie malesuada. Vivamus suscipit tortor eget felis porttitor volutpat.",
    },
    {
      image: { url: ScenePicture },
      description: "Cras ultricies ligula sed magna dictum porta. Donec sollicitudin molestie malesuada. Vivamus suscipit tortor eget felis porttitor volutpat.",
    },
    {
      image: { url: ScenePicture },
      description: "Cras ultricies ligula sed magna dictum porta. Donec sollicitudin molestie malesuada. Vivamus suscipit tortor eget felis porttitor volutpat.",
    },
    {
      image: { url: ScenePicture },
      description: "Cras ultricies ligula sed magna dictum porta. Donec sollicitudin molestie malesuada. Vivamus suscipit tortor eget felis porttitor volutpat.",
    },
  ],
};

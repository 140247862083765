import { Dispatch } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ActionTypeCommon, StoreTypeCommon } from "../index";
import { NotifyAction, NotifyActionDataType } from "../reducers/notifyReducer";

// action type define

export interface NotifyAction_Set extends ActionTypeCommon {
  type: NotifyAction.NOTIFY;
  payload: {
    event: string;
    data: NotifyActionDataType;
  };
}

export type NotifyActionType = NotifyAction_Set; // | any other action;

export const notifyAction_Set = (event: string, data: NotifyActionDataType): NotifyAction_Set => {
  return {
    type: NotifyAction.NOTIFY,
    payload: { event, data },
  };
};

export const useNotify = <T = NotifyActionDataType | undefined>(event: string) => {
  const noti = (useSelector<StoreTypeCommon, NotifyActionDataType | undefined>((store) => store.notify[event]) as unknown) as T;
  return noti;
};

export const useSetNotify = (event: string, data: NotifyActionDataType) => {
  const dispatch = useDispatch<Dispatch<NotifyActionType>>();
  dispatch(notifyAction_Set(event, data));
};

import classNames from "classnames";
import React, { useEffect } from "react";

import "./BoxRatio.scss";

export interface BoxRatioProps {
  ratio: string | number;
  component?: keyof JSX.IntrinsicElements;
  classes?: {
    root?: string;
    content?: string;
  };
}

export const BoxRatio = React.forwardRef<any, BoxRatioProps & React.AllHTMLAttributes<any>>((props, ref) => {
  const { ratio, component = "div", className, ...others } = props;
  const Tag: any = component;

  const [isAspectRatioSupported, setAspectRatioSupported] = React.useState(false);

  //Re-render again on client side when the browser supports CSS
  useEffect(() => setAspectRatioSupported(CSS?.supports("aspect-ratio", "1 / 1")), []);

  let applyRatio;

  if (typeof ratio === "number") {
    if (ratio <= 1) applyRatio = isAspectRatioSupported ? `${Math.pow(ratio, -1)}` : `${ratio * 100}%`;
    else applyRatio = isAspectRatioSupported ? `${Math.pow(ratio / 100, -1)}` : `${ratio}%`;
  } else applyRatio = isAspectRatioSupported ? `${Math.pow(parseFloat(ratio) / 100, -1)}` : ratio;

  return (
    <Tag
      {...others}
      className={classNames("box-ratio", className, props.classes?.root)}
      style={isAspectRatioSupported ? { aspectRatio: applyRatio } : { paddingBottom: applyRatio, height: 0 }}
      ref={ref}
    >
      {isAspectRatioSupported ? props.children : <div className={classNames("box-ratio__content", props.classes?.content)}>{props.children}</div>}
    </Tag>
  );
});

export default BoxRatio;

import { loadScript } from "libs/react-hooks/script-loader";

const polyfills = () => {
  // base on browser support it will skip polyfill if browser already supported
  // polyfill: es (es2015), Intersection Observer, Resize Observer
  // loadScript("https://polyfill.app/api/polyfill?minify&features=es,intersection-observer,resize-observer");
  loadScript("https://polyfill.io/v3/polyfill.min.js?features=es2015,IntersectionObserver,ResizeObserver");
};

export default polyfills;

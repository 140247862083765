import { AboutUsDataType } from "pages/about-us/AboutUs";

import DesktopBanner from "assets/images/about-us/desktop-banner.jpg";
import AustalLogo from "assets/images/homepage/sister-company/Austal.png";
import SGCLogo from "assets/images/homepage/sister-company/SGC.png";
import XTLogo from "assets/images/homepage/sister-company/XT-logo.png";

export const About_Us_Mock_Data: AboutUsDataType = {
  desktopBanner: { url: DesktopBanner },
  contentBanner:
    "<h1>ABOUT US</h1><h3>Sed porttitor lectus nibh. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Nulla quis lorem ut libero malesuada feugiat. Cras ultricies ligula sed magna dictum porta. Donec sollicitudin molestie malesuada.</h3>",
  aboutUsText: "ABOUT US",
  content: `<h2>Straightforward by all measures</h2>

    <p>Easy-Laser&reg; is the world&rsquo;s leading manufacturer and supplier of laser measurement systems for all types of industry. We provide extreme accuracy and precision. But that&rsquo;s not what sets us apart. Today, when virtually anyone with a decent laser can do &ldquo;straight&rdquo;, to get ahead, you need to be a bit more forward-thinking.</p>
    
    <p>Because, in the long run, what really counts is neither the absolute straightness of an individual component nor the precise alignment of shafts. It&#39;s what these measures add up to: Increased productivity and the saving of resources. Those are the things we ultimately deliver. And from that perspective our most important task is to help you make the road leading there as free from bumps and bends as possible.</p>
    
    <p>That means developing user-friendly measurement and alignment systems that are as easy to get your head around, as they are versatile and scalable. It also means shortening delivery times, extending warranties and optimizing training and support.</p>
    
    <p>Moreover, you can always expect us, or any of our partners, to give you an honest opinion on which of our products are crucial to your operations and which you can do without. What really needs to be aligned and what not. So that what we offer you is a solution perfectly aligned with your needs &ndash; and your budget.</p>
    
    <p>Regardless of whether you&#39;re a service technician, a purchaser or the CFO of a multinational industrial group, you&#39;ll find Easy-Laser truly easy to deal with. Or as we like to put it &ndash; straightforward by all measures.</p>
    
    <h2>Over 25 years of experience and all our expertise in-house</h2>
    
    <p>We have more than 25 years of experience of laser measurement technology. Since the start, everything from product development to manufacturing has been carried out in our facilities in M&ouml;lndal, Sweden. By forming a close-knit team of designers specialized in mechanics, electronics, optics and programming, we have managed to achieve better customization of mechanics and software, as well as short development times. This is supported by also having access to our own well-equipped workshop with CNC-machines, where the slightest change can be evaluated.</p>
    `,
  brands: {
    title: "BRANDS",
    logos: [{ logo: { url: AustalLogo } }, { logo: { url: SGCLogo } }, { logo: { url: XTLogo } }],
  },
  sisterCompany: {
    title: "SISTER COMPANY",
    logos: [{ logo: { url: AustalLogo } }, { logo: { url: SGCLogo } }, { logo: { url: XTLogo } }],
  },
};
